export type Props = {
    title: string;
    saveText: string;
    showModal: boolean;
    closeModal(): void;
    children?: React.ReactNode;
    noHeader?: boolean;
    onSave(): void;
    singleButton?: boolean;
};

export default function Modal(props: Props) {
    const {title, saveText, showModal, closeModal, children, noHeader, onSave, singleButton} = props;

    function handleSave() {
      onSave();
      closeModal();
    }

    return (
      <>
        {showModal ? (
          <>
            <div
              className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
              <div className="relative w-full my-6 mx-auto w-2/3 max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  {noHeader === true ? <></> : <div className="flex items-start justify-between p-5 border-b border-solid bg-slate-50 rounded-t">
                    <h3 className="text-3xl font-semibold">
                      {title}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-white border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none rounded-3xl"
                      onClick={closeModal}
                    >
                      <span style={{lineHeight: '1.5rem'}} className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      &#10006;
                      </span>
                    </button>
                  </div>}
                  {/*body*/}
                  {children}
                  {/*footer*/}
                  {singleButton ?
                  <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="bg-gradient-to-t from-red-500 to-red-400 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleSave}
                    >
                      {saveText}
                    </button>
                  </div> : 
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      className="bg-gradient-to-t from-red-500 to-red-400 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleSave}
                    >
                      {saveText}
                    </button>
                  </div>}
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </>
    );
  }