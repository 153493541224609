import { useCallback, useEffect, useRef } from "react";
import { CategoryReason } from "../../context/category/CategoryState";

/**
 * Combo control properties.
 */
type Props = {
    options: CategoryReason[],
    answer?: CategoryReason,
    callback: Function,
    isComplaint: boolean
}

/**
 * Represents a user-selectable Combo-Box.
 * 
 * @param {object} options - The options to be displayed by the combo-box.
 * @param {function} callback - Callback method to be called upon selection change.
 * @returns - Renderable JSX element.
 */
function ComboControl({options, answer, callback, isComplaint} : Props) {
    const htmlSelectRef = useRef<HTMLSelectElement>(null);

    useEffect(() => {
        // console.log('Combo control handler fired')
        if (!isComplaint) {
            const val = htmlSelectRef.current?.value;

            if (val !== "DEFAULT") {
                callback(val)   
            }
        }
    }, [isComplaint]);

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        callback(event.target.value);
    }

    return(

        <div className="mt-2 flex flex-col">

            <h3>Why?</h3>
            <select className="mt-2 rounded-md p-3 bg-gray-100 shadow-inner" 
                    defaultValue={(answer !== undefined) ? `${answer.key}:${answer.faultCode}` : "DEFAULT"} 
                    onChange={onChange}
                    ref={htmlSelectRef}>

                {/*Default "unselected" option*/}
                <option value="DEFAULT" disabled hidden>Please select a reason</option>
                
                {
                    // //Map all passed option data to combo-box options
                    options.map(
                        (option) => {
                            return <option value={`${option.key}:${option.faultCode}:${option.description}`} key={option.key}>{option.description}</option>
                        }
                    )
                }

            </select>

        </div>

    );

}

export default ComboControl;