import React, { useState } from "react";

/**
 * Bool control properties.
 */
type Props = {
    answer: boolean | null,
    onTrue: Function,
    onFalse: Function
}

/**
 * Represents a user-selectable True/False Radio-Button.
 * 
 * @param {bool} answer - The initial state of the control. This can also be set to 'null' to provide an "unselected" state.
 * @param {function} onTrue - A callback that is called when a user selects the truthy option.
 * @param {function} onFalse - A callback that is called when a user selects the falsy option.
 * @returns - Renderable JSX element.
 */
const BoolControl = ({answer, onTrue, onFalse} : Props) => {
    
    //Provide state and set to passed initial answer
    var [answerIn, setAnswerIn] = useState(answer);

    return(
        <div>
            <div className="flex mt-2">
                <h5 className="mr-2 text-md">Yes</h5>
                <div className="w-7 h-7 bg-gray-300 rounded-lg shadow-inner flex" onClick={() => {onTrue(); setAnswerIn(true)}}>
                    <div className={"w-5 h-5 bg-red-500 m-auto rounded-lg transition-transform duration-200 ease-in-out transform-gpu " + (answerIn === true && answerIn != null ? "scale-100" : "scale-0")} />
                </div>

                <h5 className="mr-2 ml-4 text-md">No</h5>
                <div className="w-7 h-7 bg-gray-300 rounded-lg shadow-inner flex" onClick={() => {onFalse(); setAnswerIn(false)}}>
                    <div className={"w-5 h-5 bg-red-500 m-auto rounded-lg transition-transform duration-200 ease-in-out transform-gpu " + (answerIn === false  && answerIn != null ? "scale-100" : "scale-0")} />
                </div>
            </div>
        </div>
    );

}

export default BoolControl