import { useNavigate } from "react-router";
import QrReader from "react-qr-reader";
import NavigationButton from "../components/Buttons/NavigationButton";
import qrIcon from '../assets/img/qrScanIcon.png'
import QRPlateUtils from "../helpers/QRPlateUtils";

type Props = {
    baseRoute: string,
    homeRoute: string
}

const QREntry = ({baseRoute, homeRoute}: Props) => {

    const navigate = useNavigate();

    //On QR code scan.
    const handleScan = (data: string | null) => {
        
        if(data){
            
            //Strip the plate URL and check if the number found is valid before navigating.
            data = QRPlateUtils.stripPlateURL(data);
            if(QRPlateUtils.validatePlateNumber(data)) navigate(baseRoute+"?plate="+data);

        }
        
    };

    //Log error should the QR code fail to scan.
    const handleError = (data: string | null) => { console.log("something went wrong"); }

    return(
        <div className="flex flex-col flex-grow max-w-lg w-full">

            <div className="p-4 flex flex-col my-auto">

                <div className="flex justify-center space-x-4 my-auto mb-14">
                    <img src={qrIcon} alt="QR Scan Icon Red" className="w-20 h-20 float-right"/>
                    <div className="flex">
                        <h2 className="text-lg font-semibold my-auto">Please scan the<br/>QR Code</h2>
                    </div>
                    
                </div>

                <div className="flex flex-col my-auto">
                
                    <QrReader
                        className="sd: w-full mx-auto shadow-md"
                        onScan={handleScan}
                        onError={handleError}
                    />

                </div>
            </div>
            
            <div className="p-4 space-x-5 mb-0 flex">
                <NavigationButton disabled={false} text="Back" action={() => navigate(homeRoute)} dominant={true}/>
            </div>

        </div>
    );

}

export default QREntry;