import { useState } from 'react';
import VSSIconRed from '../assets/img/vssIconRed.png'
import NavigationButton from '../components/Buttons/NavigationButton';
import { useNavigate } from 'react-router';

type Props = {
    baseRoute: string,
    homeRoute: string
}

const PlateEntry = ({baseRoute, homeRoute}: Props) => {

    //Capture plate number
    const [plateNumberInput, setInput] = useState("");

    const navigate = useNavigate();

    return(
        <div className="flex flex-col flex-grow justify-center max-w-lg w-full">
            
            <div className="my-auto">
                <div className="flex justify-center space-x-4 my-auto mb-8">
                    <img src={VSSIconRed} alt="QR Scan Icon Red" className="w-20 h-20"/>
                    <div className="flex">
                        <h2 className="text-lg font-semibold my-auto">Please enter the<br/>Plate Number</h2>
                    </div>
                    
                </div>

                <form className="flex flex-col px-10" onSubmit={() => {if(plateNumberInput !== "") navigate(baseRoute + "?plate="+plateNumberInput)}}>
                    <div className="flex flex-col text-center">
                        <input 
                            defaultValue={plateNumberInput}
                            onInput={e => {e.currentTarget.value = e.currentTarget.value.toUpperCase(); setInput(e.currentTarget.value.toString())}}
                            autoComplete="off"
                            autoCorrect="off" 
                            spellCheck={false}
                            placeholder="VSS123456789"
                            className="sm:w-60 md:w-75 shadow border rounded-lg p-3 text-lg text-center mx-auto font-semibold focus:outline-none focus:border-red-500" 
                            type="text"
                        />
                    </div>
                </form>
            </div>
            
            <div className="p-4 space-x-5 mb-0 flex">

                <NavigationButton text="Back" disabled={false} dominant={false} action={() => {navigate(homeRoute)}}/>
                <NavigationButton text="Next" disabled={(plateNumberInput === "")} dominant={true} action={
                    () => {
                        navigate(baseRoute + "?plate="+plateNumberInput)
                    }
                }/>

            </div>
        </div>
    );

}

export default PlateEntry;