import { useState } from 'react';
import InfoSVG from '../../assets/svg/info';
import StarSVG from '../../assets/svg/star'
import { CategoryEntry } from '../../context/category/CategoryState';
import { EquipmentAction, EquipmentActionType } from '../../context/equipment/EquipmentReducer';
import { EquipmentRating, useEquipmentStateContext } from '../../context/equipment/EquipmentState';
import { calculateRating } from '../../helpers/StoreUtils';
import BoolControl from '../Controls/BoolControl';
import ComboControl from '../Controls/ComboControl';

type props = {
    reportData: EquipmentRating,
    categoryData: CategoryEntry
}

const StarCard = ({ reportData, categoryData } : props) => {

    //Get equipment dispatch method.
    const {dispatch: equipmentDispatch} = useEquipmentStateContext();

    //State management for hiding/revealing the reasoning combo-box.
    const [comboExpanded, setComboExpanded] = useState(!reportData.flag);

    return(
        <div className="flex flex-col text-gray-600 mx-auto p-4 bg-gradient-to-b from-gray-100 to-gray-200 shadow-md rounded-lg md:w-[28rem]">

            <div className="text-2xl flex font-semibold">
                <StarSVG classProps="w-14 h-14 mr-5" rating={calculateRating(reportData, categoryData)} />
                <p className="my-auto">{reportData.name.charAt(0).toUpperCase()+reportData.name.slice(1)}</p>
            </div>

            <div className="py-3">
                <p>{categoryData.definition}</p>
            </div>

            <BoolControl 
                answer={reportData.flag} 
                onTrue={
                    () => {
                            
                            console.log(`FaultCode: ${reportData.faultCode}`);

                            const action: EquipmentAction = {
                                type: EquipmentActionType.UPDATE_CRITERIA,
                                payload: { 
                                    criteria: reportData.name,
                                    newReasonCode: reportData.reasonCode,
                                    newFaultCode: reportData.faultCode ? reportData.faultCode! : null,
                                    newDescription: reportData.description,
                                    newValue: true,
                                }
                            }

                            equipmentDispatch(action);
                            setComboExpanded(false); 

                        }
                    }
                onFalse={
                    () => {

                            const action: EquipmentAction = {
                                type: EquipmentActionType.UPDATE_CRITERIA,
                                payload: { 
                                    criteria: reportData.name,
                                    newValue: false,
                                }
                            }

                            equipmentDispatch(action);
                            setComboExpanded(true);  

                        }
                    }    
            />

            {(categoryData.options && reportData.flag === false) ? 
            <div className={"overflow-hidden transition-height mt-2 duration-200 ease-in-out " + (comboExpanded ? "h-[5.25rem]" : "h-0")}>
                <div className="flex-col text-left">
                    <ComboControl 
                        options={categoryData.options} 
                        callback={ 
                            
                            (value: string) => {
                            
                                //Obtain category code of selected option
                                const catCode = value;

                                // console.log(`cat code: ${catCode}`)

                                const [reasonCode, faultCode, description] = catCode.split(':');

                                const action: EquipmentAction = {
                                    type: EquipmentActionType.UPDATE_CRITERIA,
                                    payload: { 
                                        criteria: reportData.name,
                                        newReasonCode: reasonCode,
                                        newFaultCode: faultCode === 'undefined' ? null : faultCode,
                                        newValue: false,
                                        newDescription: description
                                    }
                                }

                                equipmentDispatch(action);

                            }

                        }
                        answer={
                            (categoryData.options) ?
                            categoryData.options.find(
                                    option => option.key === reportData.reasonCode
                                ) : undefined
                            }
                        isComplaint={reportData.flag!} />
                </div>
            </div>
            :
            <></>
            }
            
            {(reportData.exemption !== null && reportData.exemption !== undefined && !reportData.flag) ? 
                <div className={"bg-gray-300 p-3 shadow-inner rounded-lg mt-3 overflow-scroll overflow-x-hidden"}>
                    <InfoSVG/><p>{reportData.exemption.description}</p>
                </div>
            : 
            <></>
            }
            
        </div>
       
    );

}

export default StarCard;