import React from "react";
import ValidationFieldWrapper from "./ValidatedFieldWrapper";

export type Props = {
    label: string,
    rows: number,
    value: string,
    onChange(val: string): void,
    placeholder?: string,
    required?: boolean,
    errorMessage?: string,
    validationHandler?(): void,
    setErrorMessageHandler?(val: string): void
};

export default function TextAreaField({label, 
rows, value, 
onChange, placeholder, 
required, errorMessage,
validationHandler,
setErrorMessageHandler}: Props) {
    function onChangeImpl(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (setErrorMessageHandler) {
            setErrorMessageHandler('');
        }
        
        onChange(e.target.value);
    }

    function onBlur(e: React.FocusEvent<HTMLTextAreaElement>) {
        if (validationHandler) {
            validationHandler();
        }
    }
    
    return (
        <ValidationFieldWrapper
        label={label}
        required={required}
        errorMessage={errorMessage}>
            <textarea
            className={`
                form-control
                block
                w-full
                px-3
                py-1.5
                text-gray-700
                bg-white bg-clip-padding
                border 
                ${errorMessage ? 'border-red-500' : 'border-gray-200' } 
                rounded
                transition
                ease-in-out
                m-0
                focus:outline-none 
                focus:bg-white 
                focus:border-gray-500
            `}
            style={errorMessage ? {  } : {}}
            rows={rows}
            placeholder={placeholder}
            onChange={onChangeImpl}
            value={value}
            onBlur={onBlur}
            ></textarea>
        </ValidationFieldWrapper>
    );
}