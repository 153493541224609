type Props = {
    classProps: string,
    rating: number
}

//TODO: Refactor "classProps" nastiness
const StarSVG = ({classProps, rating} : Props) => {

    rating = (isNaN(rating)) ? 0.0 : rating;
    var starFill = 23.0-(23.0*rating);

    return(
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" className={classProps} width="24" height="24" viewBox="0 0 24 24">
                <defs>
                    <linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
                        <stop offset="20%" stopColor="#ef4444"/>
                        <stop offset="100%" stopColor="#c43737"/>
                    </linearGradient>
                    <clipPath id={"starClip_" + starFill}>
                        <rect x="0" y={starFill} width="200" height="200" />
                    </clipPath>
                    <clipPath id={"negativeStarClip_" + starFill}>
                        <rect x="0" y="0" width="200" height={starFill} />
                    </clipPath>
                </defs>

                <path clipPath={"url(#starClip_"+starFill+")"} fill="url('#gradient')" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
                <path clipPath={"url(#negativeStarClip_"+starFill+")"} fill="#cfcfcf" d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"/>
            </svg>
            
        </div>
        
    );

}

export default StarSVG;