import { EquipmentRating, EquipmentStateProvider, EquipmentStore, EquipmentStoreData } from "./EquipmentState"

/**
 * Types of actions possible for dispatch
 */
export enum EquipmentActionType {
    LOAD_DATA = 'LOAD_DATA',
    UPDATE_CRITERIA = 'UPDATE_CRITERIA',
    CLEAR_DATA = 'CLEAR_DATA',
    ADD_PHOTO = 'ADD_PHOTO',
    REMOVE_PHOTO = 'REMOVE_PHOTO',
    UPDATE_LAST_SURVEY = 'UPDATE_LAST_SURVEY'
}

export enum RatingType {
    payment = 'payment',
    position = 'position',
    price = 'price',
    range = 'range',
    visibility = 'visibility'
}

/**
 * Actions and their associated payloads.
 */
export type EquipmentAction = 
    { type: EquipmentActionType.LOAD_DATA, payload: {data: EquipmentStoreData}} | 
    { type: EquipmentActionType.UPDATE_CRITERIA, payload: {criteria: string, newValue: boolean, newReasonCode?: string, newFaultCode?: string | null, newDescription?: string} } |
    { type: EquipmentActionType.ADD_PHOTO, payload: {photoUrl: string} } |
    { type: EquipmentActionType.REMOVE_PHOTO, payload: {photoUrl: string} } |
    { type: EquipmentActionType.UPDATE_LAST_SURVEY, payload: {date: string} } |
    { type: EquipmentActionType.CLEAR_DATA };

const emptySampleEquipmentData: EquipmentStoreData = {
    equipment: {
        number: "10145853",
        plate: "00V0004"
    }
}

const sampleEquipmentData: EquipmentStoreData = {
    equipment: {
        number: "10052765",
        plate: "B3813"
    },
    outlet: {
        number: 2510620,
        name: "A&G INDUSTRIES"
    },
    date: "2022-01-07T11:30:00",
    ratings: [
        {
            name: "payment",
            flag: false,
            reasonCode: "COIN",
            exemption: {
                description: "Service Request created",
                date: "2022-03-13T00:00:00",
                rating: 0.9
            }
        },
        {
            name: "position",
            flag: false
        },
        {
            name: "price",
            flag: true
        },
        {
            name: "range",
            flag: false,
            reasonCode: "TOP8"
        },
        {
            name: "visibility",
            flag: true
        }
    ]
}

const filterPhotos = (data: EquipmentStoreData) => {
    if (data.photos === undefined ||
        data.photos === null) {
        return;
    }

    data.photos = data.photos?.filter(photo => !photo.endsWith('*'))
}

const fixRatings = (data: EquipmentStoreData) => {
    // Add ratings if doesn't exist
    //If no report exists, generate new empty report data
    if (data.ratings === undefined || data.ratings === null) {
        data.ratings = []
    }

    const ratingNames = data.ratings.map(rating => rating.name)

    for (let rating in RatingType) {
        if (!ratingNames.includes(rating)) {
            data.ratings.push({ name: rating, flag: null })    
        }
    }
}

const fixPhotos = (data: EquipmentStoreData) => {
    // Add photos array if doesn't exist
    if(data.photos === undefined || data.photos === null) { data.photos = []; }

    // mark old report photos with '*' so we know to filter them out on any other action
    data.photos = data.photos.map(photo => photo + '*')
}

export const equipmentReducer = (state: EquipmentStoreData, action : EquipmentAction) => {

    //Perform actions depending on the type of the passed action.
    switch(action.type){

        //Load data from sample state (replace with database call).
        case EquipmentActionType.LOAD_DATA:

            console.log("ACTION DATA");
            console.log(action.payload.data);

            var newState = {...action.payload.data}

            fixRatings(newState)
            
            // get rid of fix photos
            // fixPhotos(newState)
            // clear photos from previous survey
            newState.photos = [];

            //Push sample data 
            return newState;

        //Update criteria
        case EquipmentActionType.UPDATE_CRITERIA:

            //Extract payload data and copy existing state
            const payloadData = action.payload;
            var newState = {...state};

            //Don't perform action if data hasn't been loaded.
            if(newState === {} || newState.ratings === undefined) {return newState;}
            
            filterPhotos(newState)

            //Find rating.
            const rating = newState.ratings.find(rating => rating.name === payloadData.criteria)
            if(!rating) return newState;

            //If an exemption exists, remove it.
            // if(rating.exemption){ rating.exemption = undefined; }
            if(rating.reasonCode){ rating.reasonCode = undefined; }
            if(rating.faultCode){ rating.faultCode = undefined; }
            if(rating.caseNumber){ rating.caseNumber = undefined; }
            if(rating.description){ rating.description = undefined; }
            
            //Apply payload
            rating.flag = payloadData.newValue;

            if (!rating.flag) {
                if( payloadData.newReasonCode ) rating.reasonCode = payloadData.newReasonCode;
                if( payloadData.newFaultCode ) rating.faultCode = payloadData.newFaultCode;
                if( payloadData.newDescription ) rating.description = payloadData.newDescription;
            }

            return newState;

        //Add a photo to the report
        case EquipmentActionType.ADD_PHOTO: {

            //Extract photo URL and copy existing state
            const {photoUrl} = action.payload;
            var newState = {...state};

            //Don't perform action if data hasn't been loaded.
            if(newState === {}) { return newState; }
            if(newState.photos === undefined || newState.photos === null){ newState.photos = []; }

            filterPhotos(newState)

            //Add photo to new state
            newState.photos = [...newState.photos, photoUrl];

            return newState;

        }

        case EquipmentActionType.REMOVE_PHOTO: {

             //Extract photo URL and copy existing state
             const {photoUrl} = action.payload;
             var newState = {...state};
 
             //Don't perform action if data hasn't been loaded.
             if(newState === {} || newState.photos === undefined) {return newState;}
 
             filterPhotos(newState)

             //Remove photo from report
             newState.photos = [...newState.photos.filter(photo => photo !== photoUrl)];
 
             return newState;

        }

        case EquipmentActionType.UPDATE_LAST_SURVEY: {
            //Extract payload data and copy existing state
            const {date} = action.payload;
            var newState = {...state};

            //Don't perform action if data hasn't been loaded.
            if(newState === {}) {return newState;}

            filterPhotos(newState)

            newState.date = date;

            return newState;
        }

        //Clear ALL data from data-store
        case EquipmentActionType.CLEAR_DATA:
            return {};

        default: 
            return state;
            

    }

}