import HeadingLabel from './HeadingLabel';

export type Props = {
    label: string,
    children?: React.ReactNode
};

export default function FieldGrouping({label, children}: Props) {
    return (
        <div>
            <HeadingLabel label={label} />
            <div className='flex flex-col gap-2'>
                {children}
            </div>
        </div>
    );
}