import ValidationFieldWrapper from "./ValidatedFieldWrapper";

export type Props = {
    label: string,
    value: string,
    type?: string,
    onChange(val: string): void,
    placeholder?: string,
    required?: boolean,
    errorMessage?: string,
    validationHandler?(): void,
    setErrorMessageHandler?(val: string): void
};

export default function InputField({label, 
    value, type, 
    onChange, placeholder, 
    required, errorMessage,
    validationHandler,
    setErrorMessageHandler
}: Props) {
    function onChangeImpl(e: React.ChangeEvent<HTMLInputElement>) {
        if (setErrorMessageHandler) {
            setErrorMessageHandler('');
        }
        
        onChange(e.target.value);
    }

    function onBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (validationHandler) {
            validationHandler();
        }
    }

    return (
        <ValidationFieldWrapper
        label={label}
        required={required}
        errorMessage={errorMessage}>
        <input onChange={onChangeImpl} type={type ?? 'text'} 
        className="appearance-none 
        block 
        w-full 
        bg-white 
        text-gray-700 
        border 
        border-gray-200 
        rounded 
        py-3 
        px-4 
        leading-tight 
        focus:outline-none 
        focus:bg-white 
        focus:border-gray-500" 
        value={value}
        placeholder={placeholder}
        onBlur={onBlur}
        style={{minHeight: '3rem'}}
         />
        </ValidationFieldWrapper>
    );
}