import { ErrorSVG } from "../../assets/svg/error"
import { SuccessSVG } from "../../assets/svg/success"

export enum MessageType { WARNING, ERROR, SUCCESS }

type props = {
    type: MessageType,
    message: string
}

// todo implement modal that shows error message and navigates back to home screen on display message error
export class DisplayMessageError extends Error {
    type: MessageType;

    constructor(errorMessage: string, type: MessageType) {
        super(errorMessage)
        this.name = "DisplayMessageError"
        this.message = errorMessage
        this.type = type;
    }
}

export const Message = ({type, message}: props) => {

    return(

        <div className="flex justify-center text-center my-auto mx-10">
            <div className="flex flex-col space-y-4">

                {(type === MessageType.ERROR) && <ErrorSVG classProps="mx-auto w-20 h-20 text-red-500"/>}
                {(type === MessageType.WARNING) && <ErrorSVG classProps="mx-auto w-20 h-20 text-yellow-600"/>}
                {(type === MessageType.SUCCESS) && <SuccessSVG classProps="mx-auto w-20 h-20 text-emerald-500"/>}

                {(type !== MessageType.SUCCESS) && <h1 className="text-xl font-semibold text-gray-600">Someting went wrong</h1>}
                {(type === MessageType.SUCCESS) && <h1 className="text-xl font-semibold text-gray-600">Success</h1>}
                <p>{message}</p>
            </div>
        </div>

    )

}