import { CategoryStoreData } from "./CategoryState";

/**
 * Types of actions possible for dispatch
 */
export enum CategoryActionType {
    LOAD_DATA = 'LOAD_DATA',
    UPDATE_CRITERIA = 'UPDATE_CRITERIA',
    CLEAR_DATA = 'CLEAR_DATA'
}

/**
 * Actions and their associated payloads.
 */
export type CategoryAction = 
    { type: CategoryActionType.LOAD_DATA, payload: {data: CategoryStoreData}} | 
    { type: CategoryActionType.CLEAR_DATA };

export const categoryReducer = (state: CategoryStoreData, action : CategoryAction) => {

    var newState: CategoryStoreData = {};

    //Perform actions depending on the type of the passed action.
    switch(action.type){

        //Load data from sample state (replace with database call).
        case CategoryActionType.LOAD_DATA:
            return action.payload.data;
            // return {...sampleCategoryData};

        //Clear ALL data from data-store
        case CategoryActionType.CLEAR_DATA:
            return {};

        default:
            return state;

    }

}