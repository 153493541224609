export const defaultRequiredStringInputValidator = function(value: string,
    errorMessage: string,
    setErrorMessage: (value: string) => void) {
    if (!value) {
        setErrorMessage(errorMessage);
        return false;
    } 
    
    return true;
}

export const emailInputValidator = function(value: string,
    errorMessage: string,
    setErrorMessage: (value: string) => void) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
        setErrorMessage(errorMessage);
        return false;
    } 
    
    return true;
}

export const nullEmailInputValidator = function(value: string,
    errorMessage: string,
    setErrorMessage: (value: string) => void) {
    if (!value) {
        return true;
    }
    else {
        return emailInputValidator(value, errorMessage, setErrorMessage);
    }
}

export const nullPhoneInputValidator = function(value: string,
    errorMessage: string,
    setErrorMessage: (value: string) => void) {
    if (!value) {
        return true;
    }
    else {
        return phoneInputValidator(value, errorMessage, setErrorMessage);
    }
}

export const phoneInputValidator = function(value: string,
    errorMessage: string,
    setErrorMessage: (value: string) => void) {
    const phoneRegex = /^(\+)?\d+[\d\s]*$/;
    if (!phoneRegex.test(value)) {
        setErrorMessage(errorMessage);
        return false;
    } 
    
    return true;
}

export const defaultRequiredIntInputValidator = function(value: string,
    fieldName: string,
    setErrorMessage: (value: string) => void) {
    if (!value) {
        setErrorMessage(`${fieldName} is required.`);
        return false;
    }
    else {
        try { 
            var n = parseInt(value);
            if (n < 1) {
                // value must be positive integer
                setErrorMessage(`${fieldName} must be greater than 0.`);
                return false;
            }
        } catch {
            // can't parse to an int must not be valid
            setErrorMessage(`${fieldName} must be greater than 0.`);
            return false;
        }
    }
    
    console.log(fieldName);
    console.log(value);
    return true;
}

export const defaultRequiredBoolInputValidator = function(value: boolean | null,
    errorMessage: string,
    setErrorMessage: (value: string) => void) {
    if (value == null) {
        setErrorMessage(errorMessage);
        return false;
    } 
    
    return true;
}

type ConditionFunc = () => boolean;

export const isValid = (conditionFuncs: ConditionFunc[]): boolean => {
    var conditionFuncResults = conditionFuncs.filter(result => !result()).length;
    console.log(conditionFuncResults);
    var result = (conditionFuncResults == 0);
    console.log(result);
    return result;
}