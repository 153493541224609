import { motion } from "framer-motion";
import CameraSVG from "../../assets/svg/camera";
import StarSVG from "../../assets/svg/star";
import { CategoryEntry, useCategoryStateContext } from "../../context/category/CategoryState";
import { EquipmentRating, useEquipmentStateContext } from "../../context/equipment/EquipmentState";
import { calculateRating } from "../../helpers/StoreUtils";

type Props = {
    currentSlide: number,
    callback: Function,
}

const StarIndicator = ({ currentSlide, callback } : Props) => {

    //Gain access to equipment state
    const { state: equipmentState } = useEquipmentStateContext();
    const { state: categoryState } = useCategoryStateContext();

    //Ensure equipment state has loaded
    if(!equipmentState.ratings || !categoryState.categories) throw new Error("Could not load StarIndicator: Data has not been loaded!");

    return(
        <div>
            <div className="flex justify-center space-x-4 mt-3 mb-2">
                {

                    //Map all ratings to their own star icon
                    categoryState.categories.map(
                        
                        (category: CategoryEntry, index: number) => {

                            //Obtain category associated with rating and calculate rating's star value.
                            //const category = categoryState.categories!.find(category => category.name === rating.name);
                            const rating = equipmentState.ratings!.find(rating => rating.name === category.name);
                            const starVal = calculateRating(rating!, category!);

                            return(
                                <div key={rating!.name} onClick={() => {callback(index);}}>
                                    <StarSVG 
                                    classProps="w-8 h-8" 
                                    rating={starVal}
                                    />
                                    {(currentSlide === index) ? 
                                        <div id="underAnimate" className="h-1 w-8 bg-red-500 mt-1 rounded-2xl" /> 
                                    : <div/>}
                                </div>
                            )

                        }
                        
                    )

                }
                <div key="Camera" onClick={() => {callback(5);}}>
                    <CameraSVG classProps="w-8 h-8"/>
                    {(currentSlide === 5) ? <div id="underAnimate" className="h-1 w-8 bg-red-500 mt-1 rounded-2xl" /> : <div/>}
                </div>
                
            </div>
        </div>
    );

}

export default StarIndicator;