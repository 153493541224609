/**
 * Properties for CameraSVG.
 */
type Props = {
    classProps: string
}

/**
 * Returns an SVG element of a camera.
 * 
 * @param {string} classProps - Please don't ask why I had to feed the class name as a string prop... 
 * @returns - An SVG camera element.
 */
const CameraSVG = ({classProps} : Props) =>{

    return(
        <svg className={classProps} xmlns="http://www.w3.org/2000/svg" width="61.347" height="47.655" viewBox="0 0 61.347 47.655">
            <path id="Path_8" data-name="Path 8" d="M191.949-1700.68a3.4,3.4,0,0,0-2.659,2.465,7.188,7.188,0,0,0-.092,1.838l-.011,1.56-14.234.011c-13.167.01-14.259.014-14.565.07a8.132,8.132,0,0,0-2.345.743,8.061,8.061,0,0,0-4.3,5.254c-.063.257-.144.645-.18.863-.06.38-.063,1.074-.063,13.942s0,13.562.063,13.942c.035.219.116.606.18.863a8.06,8.06,0,0,0,4.3,5.254,7.9,7.9,0,0,0,2.395.743c.366.06,1.652.063,23.736.063s23.37,0,23.736-.063a8,8,0,0,0,2.395-.743,7.969,7.969,0,0,0,3.194-2.782,8.246,8.246,0,0,0,1.285-3.335c.06-.38.063-1.074.063-13.942s0-13.562-.063-13.942a8.246,8.246,0,0,0-1.285-3.335,8.065,8.065,0,0,0-3.106-2.743,7.6,7.6,0,0,0-1.606-.6,8.535,8.535,0,0,0-2.645-.25l-1.451-.018-.011-1.553a7.19,7.19,0,0,0-.092-1.838,3.451,3.451,0,0,0-2.419-2.419l-.282-.081-4.842-.007C193.182-1700.725,192.153-1700.718,191.949-1700.68Zm-19.193,13.53a1.466,1.466,0,0,1,.673.68.968.968,0,0,1,.109.563.969.969,0,0,1-.109.564,1.462,1.462,0,0,1-.683.683.968.968,0,0,1-.563.109.968.968,0,0,1-.563-.109,1.462,1.462,0,0,1-.683-.683.969.969,0,0,1-.109-.564.968.968,0,0,1,.109-.563,1.455,1.455,0,0,1,.919-.775A1.632,1.632,0,0,1,172.756-1687.15Zm12.931-.045a13.333,13.333,0,0,1,6.885,2.887,16.677,16.677,0,0,1,1.993,1.993,13.358,13.358,0,0,1,2.909,7.043,18.827,18.827,0,0,1-.018,2.852,13.212,13.212,0,0,1-1.314,4.438,12.741,12.741,0,0,1-2.529,3.508,13.276,13.276,0,0,1-8.1,3.86,19.982,19.982,0,0,1-2.676,0,13.368,13.368,0,0,1-7.026-2.881,16.226,16.226,0,0,1-2.057-2.057,13.367,13.367,0,0,1-2.881-7.026,19.965,19.965,0,0,1,0-2.676,13.286,13.286,0,0,1,2.176-6.057,13.951,13.951,0,0,1,3.5-3.585,13.545,13.545,0,0,1,6.582-2.349A20.652,20.652,0,0,1,185.688-1687.2Z" transform="translate(-153.5 1700.723)" fill="#8b8b8b"/>
        </svg>

    );

}

export default CameraSVG;
