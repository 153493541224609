import LoadingSpinner from "../components/Spinners/LoadingSpinner";
import NavigationButton from "../components/Buttons/NavigationButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DisplayMessageError, Message, MessageType } from "../components/Messages/Message";
import { apiConfig } from "../apiConfig";
import { useMsal } from "@azure/msal-react";
import { getIdToken, loginRedirect } from "../helpers/ApiUtils";
import { UnauthorizedError } from "../exceptions/UnauthorizedError";
import { useFormValue, useResetForm } from "../components/Survey/SurveyAtoms";

enum SubmissionStatus {
    SUBMITTING,
    SUBMITTED,
    FAILED
}

const SubmitCloseSiteSurvey = () => {

    const form = useFormValue();
    const resetForm = useResetForm();

    const navigate = useNavigate();

    // Msal hook
    const {instance, accounts} = useMsal();

    const [submitted, setSubmitted] = useState<SubmissionStatus>(SubmissionStatus.SUBMITTING);

    const [error, setError] = useState({
        message: '',
        type: MessageType.ERROR
    });

    useEffect(
        () => {
          
            async function submitForm() {
                try {
                    const token = await getIdToken(instance, accounts[0]);

                    const res = await fetch(
    
                        //Update equipment data.
                        apiConfig.closeSiteSurveyBaseUrl + "equipments/" + form?.outlet?.plateNumber,
                        {
                            mode: "cors",       //Enable CORS.
                            method: "POST",     //Use POST method.
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'X-Consumer-Id': 'Vending'
                            },
                            body: JSON.stringify(form)
                        }
                    );
    
                    if(res.ok) {
                        setSubmitted(SubmissionStatus.SUBMITTED);
                    } else if (res.status === 401) {
                        throw new UnauthorizedError();
                    } else if (res.status === 403) {
                        throw new DisplayMessageError("You do not have authorization to access this resource.", MessageType.ERROR);
                    } else {
                        console.log("Could not submit form! Check Azure Function logs.")
                        throw new DisplayMessageError("Could not submit form!", MessageType.ERROR);
                    }
                } catch (err) {
                    console.error(err);
                    if (err instanceof UnauthorizedError) {
                        await loginRedirect(instance, accounts[0]);
                    }
                    else if (err instanceof DisplayMessageError) {
                        // display error message modal
                        const displayError = err as DisplayMessageError;

                        setError({
                            message: displayError.message,
                            type: displayError.type
                        });
                    } else {
                        console.log("Unhandled exception: " + err)
                        // display unhandled error message modal
                        setError({
                            message: 'An unexpected error occured.',
                            type: MessageType.ERROR
                        });
                    }

                    setSubmitted(SubmissionStatus.FAILED);
                } finally {
                    resetForm();
                }
            }

            submitForm();
        },
        []
      );

    return(
        <div className="flex flex-col flex-1 max-w-lg w-full">
            
            <div className="flex justify-center text-center my-auto">
                {
                    submitted === SubmissionStatus.SUBMITTING && 
                        <div>
                            <LoadingSpinner/>
                            <h1 className="text-xl font-semibold text-gray-600">Submitting</h1>
                        </div>
                    ||
                    submitted === SubmissionStatus.SUBMITTED &&
                        <Message message={"Report submitted"} type={MessageType.SUCCESS}/>
                    ||
                    submitted === SubmissionStatus.FAILED &&
                        <Message message={error.message} type={error.type}/>
                }
                
            </div>

            <div className="p-4 space-x-5 mb-0 flex">
                {submitted === SubmissionStatus.SUBMITTING ? null : <NavigationButton id="done" text="Done" disabled={false} dominant={false} action={
                        () => {
                            navigate("/close-site")
                        }
                    }/>}
            </div>

        </div>
        
    );

}

export default SubmitCloseSiteSurvey;