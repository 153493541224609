import { useState } from 'react';
import StarCard from '../Cards/StarCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import StarIndicator from './CardIndicator';
import MediaCard from '../Cards/MediaCard';
import { useCategoryStateContext } from '../../context/category/CategoryState';
import {  useEquipmentStateContext } from '../../context/equipment/EquipmentState';
import { DisplayMessageError, MessageType } from '../Messages/Message';

/**
 * A container storing all star-cards, and a media-card.
 * 
 * @returns JSX element storing all star-cards and the media-card.
 */

type props = {
    setReportLoading: React.Dispatch<React.SetStateAction<boolean>>
};

const ReportContainer = ({setReportLoading}: props) => {

    //State for active slide index
    const [activeSlide, setActiveSlide] = useState(0);
    
    //Store swiper in state object for jumping
    const [swiper, setSwiper] = useState<any>(null);
    const slideTo = (index: number) => {if(swiper !== null) swiper.slideTo(index);}

    //Gain access to equipment and category states.
    const {state: equipmentState, dispatch: equipmentDispatch} = useEquipmentStateContext();
    const {state: categoryState} = useCategoryStateContext();

    //Ensure data has been loaded
    if(!equipmentState || !categoryState.categories) throw new Error("Could not load ReportContainer, data has not been loaded!");

    //Format the date appropriately
    let formattedDate: string;

    if(equipmentState.date){

        const d: Date = new Date(equipmentState.date);
        formattedDate = d.toDateString() + ", " +d.getHours()+":"+d.getMinutes();

    } else formattedDate = "Not Found";

    return(

        <div className="mt-0 mb-0 flex flex-col flex-grow">
            <div>
                <div className="mt-4 justify-center flex align-baseline">
                    <h1 className="text-lg text-gray-600 mr-2">Last Survey: </h1>
                    <h1 className="text-lg font-semibold text-gray-600">{formattedDate}</h1>
                </div>

                <StarIndicator
                    callback={(index: number) => {slideTo(index);}}
                    currentSlide={activeSlide}
                    key={activeSlide}
                />
            </div>


            <Swiper 
                onSwiper={setSwiper}
                spaceBetween={0} 
                slidesPerView={1} 
                className="flex flex-col flex-grow flex-1 my-auto w-screen"
                onTransitionStart={(swiper) => {setActiveSlide(swiper.activeIndex)}}
            >
                
                {

                    //Map all report criteria from state to card slides
                    categoryState.categories.map(
                        (category) => {

                            // //If no report exists, generate new empty report data
                            // if(!equipmentState.ratings){
                            //     equipmentState.ratings = [
                            //         { name: "payment", flag: null },
                            //         { name: "position", flag: null },
                            //         { name: "price", flag: null },
                            //         { name: "range", flag: null },
                            //         { name: "visibility", flag: null }
                            //     ]
                            // }

                            // console.log(equipmentState)

                            //Get rating data
                            const rating = equipmentState?.ratings?.find( rating => rating.name === category.name);
                            if(!rating) throw new DisplayMessageError("Could not load StarCard: " + category.name + ". Associated report data could not be found", MessageType.ERROR);
                            return (
                                <SwiperSlide className="px-4 py-2" key={rating.name}>
                                    <StarCard reportData={rating} categoryData={category}/>
                                </SwiperSlide>
                            )

                        }
                    )

                }

                <SwiperSlide className="px-4 py-2" key="MediaCard">
                    <MediaCard setReportLoading={setReportLoading}/>
                </SwiperSlide>

            </Swiper>

        </div>

    );

}

export default ReportContainer;