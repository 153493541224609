/**
 * Utilities used to parse QR code data.
 */
 export default  class QRPlateUtils{

    /**
     * Strips the plate number from the provided URL, should one be present
     * 
     * @param {string} url - URL to be stripped.
     * @returns - The Plate number.
     */
    static stripPlateURL = (url: string): string => {return url.split('?')[1].split('&')[0];}

    /**
     * Checks to see if the provided plate number is valid.
     * 
     * @param {string} plate - The plate number to be validated. 
     * @returns - True if the plate number is valid, false otherwise
     */
    static validatePlateNumber = (plate: string) => true;

}
