import React, { useContext, useReducer } from "react";
import { CategoryAction, categoryReducer } from "./CategoryReducer";

/**
 * Data model representing the category store.
 * 
 * @prop {StoreCategoryData} categoryData - All data regarding the five categories.
 * @prop {StoreEquipmentData} equipmentData - All data regarding the equipment (including report data).
 * @prop {React.Dispatch<Action>} dispatch - The dispatch method used to send actions to the reducer.
 */
export interface CategoryStore {
    state: CategoryStoreData,
    dispatch: React.Dispatch<CategoryAction>
}

/**
 * Data model representing the store's category data.
 * 
 * @prop {CategoryEntry[]} categories - Contains all categories and their associated options.
 */
export interface CategoryStoreData { categories? : CategoryEntry[] }

/**
 * Data model representing one category and its definition, along with the reasons for invalidity.
 * @prop {string} definition - Describes how the category should be measured by the user.
 * @prop {string} name - The name of the category.
 * @prop {CategoryReason[]} options - The possible options/reasons for a reduced score.
 */
export interface CategoryEntry { name: string, definition: string, options: CategoryReason[] | null };

/**
 * Data model representing a single reason for invalidity.
 * @prop {string} key - Key identifier for the reason.
 * @prop {string} description - A short description of the reason (to be shown to user).
 * @prop {number} rating - 0.0 to 1.0 floating point value representing how the reason should affect the rating, should it be selected.
 * @prop {string} faultCode - fault code
 */
export interface CategoryReason { key: string, description: string, rating: number, faultCode: string };

//Create a new context with an empty default state, and undefined dispatch method.

//NOTE: useReducer() is ALWAYS called before the context's provider is returned in the StateProvider element;
//      dispatch will never be undefined. This has been done so we can avoid having to perform unecessary nullchecks.
const defaultCategoryState: CategoryStoreData = { };
const context = React.createContext<CategoryStore>({state: defaultCategoryState, dispatch: () => null});

//Custom hook for context
export const useCategoryStateContext = (): CategoryStore => useContext(context);

//Props for provider element
type ProviderProps = {
    children?: JSX.Element
}

//State provider element
export const CategoryStateProvider = ({ children }: ProviderProps) => {

    const [state, dispatch] = useReducer(categoryReducer, defaultCategoryState);
    return <context.Provider value={{state, dispatch}} children={children}></context.Provider>

}

