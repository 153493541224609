import React, { useContext, useReducer } from "react";
import { equipmentReducer, EquipmentAction } from './EquipmentReducer';

/**
 * Data model representing the equipment store.
 *
 * @prop {EquipmentStoreData} equipmentData - All data regarding the equipment (including report data).
 * @prop {React.Dispatch<Action>} dispatch - The dispatch method used to send actions to the reducer.
 */
export interface EquipmentStore {
    state: EquipmentStoreData,
    dispatch: React.Dispatch<EquipmentAction>
}

/**
 * Data model representing a category exemption from a manager.
 * @prop {string} description - A text description from the manager explaining the exemption.
 * @prop {string} date - The date the exemption was submitted.
 * @prop {number} rating - The overriden rating. Should this exist with a later date than the report submitted, it will override the report data.
 */
export interface EquipmentRatingExemption { description: string, date: string, rating?: number };

/**
 * Data model representing the equipment's outlet data.
 * @prop {number} number - Outlet number.
 * @prop {string} name - Outlet name.
 */
export interface OutletInfo { number: number, name: string };

/**
 * Data model representing the equipment's data
 * @prop {number} number - The equipment number.
 * @prop {string} plate - The equipment's plate number.
 */
export interface EquipmentInfo { number: string, plate: string }

/**
 * Data model representing the report data for a single category.
 * @prop {string} name - The name of the category associated with the rating
 * @prop {boolean} flag - The report answer.
 * @prop {string} reasonCode - The reason for a falsy answer, should one be provided.
 * @prop {EquipmentCategoryExemption} exemption - The exemption from the manager.
 */
export interface EquipmentRating { 
    name: string, 
    flag: boolean | null, 
    reasonCode?: string, 
    faultCode?: string,
    caseNumber?: string,
    exemption?: EquipmentRatingExemption,
    description?: string
 }

/**
 * Data model representing all equipment data
 * @prop {string} division - The equipment's associated division (used to obtain category data).
 * @prop {EquipmentOutletData} outlet - Contains all info concerning the equipment's outlet.
 * @prop {EquipmentInfo} equipment - Contains all info concerning the equipment.
 * @prop {string} date - The report's last data.
 * @prop {EquipmentCategoryData[]} ratings - The report's rating data.
 * @prop {string[]} photos - URL's for report photos.
 */
export interface EquipmentStoreData {
    [index: string]: OutletInfo | EquipmentInfo | EquipmentRating[] | string[] | string | undefined;
    division?: string,
    outlet? : OutletInfo,
    equipment? : EquipmentInfo,
    date? : string,
    ratings? : EquipmentRating[],
    photos? : string[]
}

//Create a new context with an empty default state, and undefined dispatch method.

//NOTE: useReducer() is ALWAYS called before the context's provider is returned in the StateProvider element;
//      dispatch will never be undefined. This has been done so we can avoid having to perform unecessary nullchecks.
const defaultEquipmentState: EquipmentStoreData = { };
const context = React.createContext<EquipmentStore>({state: defaultEquipmentState, dispatch: () => undefined});

//Custom hook for context
export const useEquipmentStateContext = (): EquipmentStore => useContext(context);

//Props for provider element
type ProviderProps = {
    children?: JSX.Element
}

//State provider element
export const EquipmentStateProvider = ({ children }: ProviderProps) => {

    const [state, dispatch] = useReducer(equipmentReducer, defaultEquipmentState);
    return <context.Provider value={{state, dispatch}} children={children}></context.Provider>
}

