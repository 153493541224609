import Label from "./Label";
import ErrorMessageTag from "./ErrorMessageTag";
import MessageTag from "./MessageTag";

export type Props = {
    label: string,
    required?: boolean,
    errorMessage?: string,
    children?: React.ReactNode,
    messageTag?: string
}

export default function ValidationFieldWrapper({label, children, required, errorMessage, messageTag}: Props) {
    return (
    <div>
        <Label label={label} required={required} errorMessage={errorMessage} noMargin={messageTag ? true : false}/>
        {messageTag ? <MessageTag message={messageTag} /> : null}
        {children}
        {errorMessage ? <ErrorMessageTag errorMessage={errorMessage} /> : null}
    </div>);
}