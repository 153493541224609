export type Props = {
    errorMessage: string
}

export default function ErrorMessageTag({errorMessage}: Props) {
    return (
        <label 
            className="form-label 
            inline-block mb-2 
            text-red-500
            text-sm
            italic"
            >{errorMessage}
        </label>
    );
}