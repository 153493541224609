import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * Properties for LandingButton component
 */
type Props = {
    to: string,
    image: string,
    text: string
}

/**
 * Landing button displayed on the front page.
 * 
 * @param {string} to - Location navigated to on click.
 * @param {string} image - The image to be displayed on the landing button.
 * @param {string} text - The text to be showed underneath the image.
 * @returns - A JSX element containing the landing button.
 */
export default function LandingButton({to, image, text} : Props){

    //Use effect state for button press animation and navigation hook.
    const [useEffect, setEffect] = useState(false);
    const navigate = useNavigate();

    return(
        <button 
            className={`${useEffect && "animate-buttonDown"} shadow-md w-2/5 bg-gradient-to-t from-red-400 to-red-600 p-5 text-center rounded-lg h-52`}
            onClick={() => {setEffect(true);}}
            onAnimationEnd={() => {setEffect(false); navigate(to);}}
        >

            <img src={image} className="w-20 h-20 mx-auto" alt="QR Icon"></img>
            <div className="text-white text-xl pt-2">{text}</div>

        </button>
    );


}
