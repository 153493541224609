import {useRecoilState, useSetRecoilState} from 'recoil';
import BoolField from './BoolField';
import TextAreaField from './TextAreaField';
import { defaultRequiredBoolInputValidator, isValid } from "./Validation";
import {
escortRequiredState,
escortDetailsState,
siteInductionRequriedState,
specialRequimentsState,
validationFnState,
ppeRequiredState,
} from './SurveyAtoms';
import { useCallback, useEffect, useMemo, useState } from 'react';

export default function EntryRequirements() {
    const setValidationFn = useSetRecoilState(validationFnState);

    const [isEscortRequired, setIsEscortRequired] = useRecoilState(escortRequiredState);
    const [escortDetails, setEscortDetails] = useRecoilState(escortDetailsState);
    const [isSiteInductionRequired, setIsSiteInductionRequired] = useRecoilState(siteInductionRequriedState);
    const [isPpeRequired, setIsPpeRequired] = useRecoilState(ppeRequiredState);
    const [specialRequirements, setSpecialRequirements] = useRecoilState(specialRequimentsState);

    const [escortRequiredError, setEscortRequiredError] = useState('');
    const [siteInductionRequiredError, setSiteInductionRequiredError] = useState('');
    const [ppeRequiredError, setPpeRequiredError] = useState('');
    const [escortDetailsError, setEscortDetailsError] = useState('');

    useEffect(() => {
        setEscortDetailsError('')
    }, [isEscortRequired]);

    const escortDetailsValidationHandler = useCallback(() => { 
        console.log(`Escort Required: ${isEscortRequired}, escortDetails: ${escortDetails}`);

        if (isEscortRequired &&
            !escortDetails) {
            setEscortDetailsError('Please provide escort details');
            console.log(`escortDetailsValidationHandler: ${false}`);
            return false;
        }

        console.log(`escortDetailsValidationHandler: ${true}`);
        return true;
    }, [isEscortRequired, escortDetails]);

    const escortRequiredValidator = useCallback(
        () => defaultRequiredBoolInputValidator(isEscortRequired,
            'Please specify whether escort is required',
            setEscortRequiredError)
    , [isEscortRequired]);

    const siteInductionRequiredValidator = useCallback(
        () => defaultRequiredBoolInputValidator(isSiteInductionRequired,
            'Please specify whether site induction is required',
            setSiteInductionRequiredError)
    , [isSiteInductionRequired]);

    const ppeRequiredValidator = useCallback(
        () => defaultRequiredBoolInputValidator(isPpeRequired,
            'Please specify whether PPE is required onsite',
            setPpeRequiredError)
    , [isPpeRequired]);

    const validationFuncs = [
        escortRequiredValidator,
        siteInductionRequiredValidator,
        ppeRequiredValidator,
        escortDetailsValidationHandler
    ];

    const formValidator = useCallback((): boolean => isValid(validationFuncs), 
        validationFuncs);   

    useEffect(() => {
        setValidationFn(() => formValidator);
    }, [formValidator]);

    return (
        <div className="w-full max-w-lg px-4 flex flex-col gap-4">
            <BoolField question={'Is escort required onsite?'} 
            answer={isEscortRequired}
            onChange={setIsEscortRequired}
            errorMessage={escortRequiredError}
            setErrorMessageHandler={setEscortRequiredError}
            required >
                <div className='mt-4'>
                    <TextAreaField label='Escort details'
                    value={escortDetails}
                    placeholder='e.g. Contact Bob 1 hour prior to arrange'
                    rows={3}
                    onChange={setEscortDetails}
                    required
                    errorMessage={escortDetailsError}
                    validationHandler={escortDetailsValidationHandler}
                    setErrorMessageHandler={setEscortDetailsError} />
                </div>
            </BoolField>
            <BoolField question={'Is site induction required?'} 
            answer={isSiteInductionRequired}
            onChange={setIsSiteInductionRequired}
            errorMessage={siteInductionRequiredError}
            setErrorMessageHandler={setSiteInductionRequiredError}
            required />
            <BoolField question={'PPE required?'} answer={isPpeRequired}
            onChange={setIsPpeRequired} 
            errorMessage={ppeRequiredError}
            setErrorMessageHandler={setPpeRequiredError}
            required/>
            <TextAreaField 
            label='Any Special Requirements?' 
            rows={3} 
            value={specialRequirements} 
            onChange={setSpecialRequirements}
            placeholder='e.g. Vending Trolley Required? Police Checks, Mandatory Flu Shots, Hard hat, safety glasses, safety boots etc' />
        </div>
    );
}