import { MultiValue } from 'react-select';
import {atom, selector, useRecoilValue, useResetRecoilState} from 'recoil';
import { SelectOption } from './MultiSelectField';

type BoolType = boolean | null;
const boolDefault = null;

const createBoolAtom = (key: string) => {
    return atom<BoolType>({
        key,
        default: boolDefault
    });
}

// main contact
export const mainContactFirstNameState = atom({
    key: 'mainContactFirstName',
    default: ''
});

export const mainContactLastNameState = atom({
    key: 'mainContactLastName',
    default: ''
});

export const mainContactEmailState = atom({
    key: 'mainContactEmail',
    default: ''
});

export const mainContactPhoneState = atom({
    key: 'mainContactPhone',
    default: ''
});

// CCEP contact
export const ccepContactFirstNameState = atom({
    key: 'ccepContactFirstName',
    default: ''
});

export const ccepContactLastNameState = atom({
    key: 'ccepContactLastName',
    default: ''
});

export const ccepContactEmailState = atom({
    key: 'ccepContactEmail',
    default: ''
});

export const ccepContactPhoneState = atom({
    key: 'ccepContactPhone',
    default: ''
});

// Alternate contact
export const alternateContactFirstNameState = atom({
    key: 'alternateContactFirstName',
    default: ''
});

export const alternateContactLastNameState = atom({
    key: 'alternateContactLastName',
    default: ''
});

export const alternateContactEmailState = atom({
    key: 'alternateContactEmail',
    default: ''
});

export const alternateContactPhoneState = atom({
    key: 'alternateContactPhone',
    default: ''
});

export const loadingDockRequiredState = createBoolAtom('loadingDockRequired');

export const numberOfPeopleRequiredState = atom({
    key: 'numberOfPeopleRequired',
    default: ''
});

export const loadingDockHeightState = atom({
    key: 'loadingDockHeight',
    default: ''
});

export const loadingDockLocationState = atom({
    key: 'loadingDockLocation',
    default: ''
});

export const alternateParkingAvailableState = createBoolAtom('alternateParkingAvailable');

export const alternateParkingDetailsState = atom({
    key: 'alternateParkingDetails',
    default: ''
});

export const machineBoltedDownState = createBoolAtom('machineBoltedDown');
export const unevenSurfaceState = createBoolAtom('unevenSurface');

export const typeOfFlooringState = atom({
    key: 'typeOfFlooring',
    default: ''
});

export const willMachineFitThroughDoorwayState = createBoolAtom('willMachineFitThroughDoorway');

export const doorwayHeightState = atom({
    key: 'doorwayHeight',
    default: ''
});

export const doorwayWidthState = atom({
    key: 'doorwayWidth',
    default: ''
});

export const anyStairsState = createBoolAtom('anyStairs');

export const stairCountState = atom({
    key: 'stairCount',
    default: ''
});

export const anyLiftState = createBoolAtom('anyLift');

export const anyRampState = createBoolAtom('anyRamp');

export const liftHeightState = atom({
    key: 'liftHeight',
    default: ''
});

export const liftWidthState = atom({
    key: 'liftWidth',
    default: ''
});

export const liftDepthState = atom({
    key: 'liftDepth',
    default: ''
});

export const specialRequimentsState = atom({
    key: 'specialRequirements',
    default: ''
});

// entry requirements
export const escortRequiredState = createBoolAtom('escortRequired');

export const escortDetailsState = atom({
    key: 'escortDetail',
    default: ''
});

export const siteInductionRequriedState = createBoolAtom('siteInductionRequired');

export const ppeRequiredState = createBoolAtom('ppeRequired');

// outlet state
export const equipmentLocationState = atom({
    key: 'equipmentLocation',
    default: ''
});

export const accessStartTimeState = atom({
    key: 'accessStartTime',
    default: ''
});

export const accessEndTimeState = atom({
    key: 'accessEndTime',
    default: ''
});

export type ValidationFnType = () => boolean;

export const validationFnState = atom<ValidationFnType>({
    key: 'validationFn',
    default: () => false
});

export const outletAddressState = atom({
    key: 'outletAddress',
    default: ''
});

export const outletAddressChangedState = createBoolAtom('outlessAddressChanged');

export type Outlet = {
    outletName: string,
    outletNumber: string,
    plateNumber: string,
    equipment: string,
    address: string
}

export const outletState = atom<Outlet | null>({
    key: 'outlet',
    default: null
});

export type Driver = {
    name: string,
    phone: string
}

export const driverState = atom<Driver | null>({
    key: 'driver',
    default: null
});

//atoms
export const removalReasonsState = atom<MultiValue<SelectOption> | null>({
    key: 'removalReason',
    default: null
});

export const removalReasonDetailState = atom<string>({
    key: 'removalReasonDetail',
    default: ''
});

export const removalReasonsListState = atom<string[]>({
    key: 'removalReasonsList',
    default: []
});

export const removalReasonsOptionsState = selector<SelectOption[]>({
    key: 'removalReasonsOptions',
    get: ({get}) => {
        const removalReasonsList = get(removalReasonsListState);

        return removalReasonsList.map(x => 
            {
                return {
                    value: x, 
                    label: x 
                }
            });
    }
});

type SurveyForm = {
    move: {
        type: string,
        reasons: string[]
    },
    loadingDock: null | {
        height: number,
        location: string
    },
    alternateParking: null | {
        details: string
    },
    machineBoltedDown: boolean,
    unevenSurface: boolean,
    typeOfFlooring: string,
    doorway: null | {
        height: number,
        width: number
    },
    stairs: null | {
        stepCount: number
    },
    anyRamp: boolean,
    lift: null | {
        height: number,
        width: number,
        depth: number
    },
    specialRequirements: string,
    escort: null | {
        details: string
    },
    siteInductionRequired: boolean,
    equipmentLocation: string,
    accessHours: {
        open: string,
        close: string
    },
    // removal: {
    //     reasons: string[],
    //     details: string  
    // },
    outlet: Outlet,
    updatedAddress: null | string,
    driver: Driver,
    numberOfPeopleRequired: string,
    ppeRequired: boolean,
    contacts: {
        main: {
            firstName: string,
            lastName: string,
            email: string,
            phone: string
        },
        ccep: {
            firstName: string,
            lastName: string,
            email: string,
            phone: string
        },
        alternate: {
            firstName?: string,
            lastName?: string,
            email?: string,
            phone?: string
        }
    }
}

export function useFormValue(): SurveyForm {
    const loadingDockRequired = useRecoilValue(loadingDockRequiredState);
    const loadingDockHeight = useRecoilValue(loadingDockHeightState);
    const loadingDockLocation = useRecoilValue(loadingDockLocationState);
    const alternateParkingAvailable = useRecoilValue(alternateParkingAvailableState);
    const alternateParkingDetails = useRecoilValue(alternateParkingDetailsState);
    const machineBoltedDown = useRecoilValue(machineBoltedDownState);
    const typeOfFlooring = useRecoilValue(typeOfFlooringState);
    const willMachineFitThroughDoorway = useRecoilValue(willMachineFitThroughDoorwayState);
    const doorwayHeight = useRecoilValue(doorwayHeightState);
    const doorwayWidth = useRecoilValue(doorwayWidthState);
    const anyStairs = useRecoilValue(anyStairsState);
    const stairCount = useRecoilValue(stairCountState);
    const anyLift = useRecoilValue(anyLiftState);
    const anyRamp = useRecoilValue(anyRampState);
    const liftHeight = useRecoilValue(liftHeightState);
    const liftWidth = useRecoilValue(liftWidthState);
    const liftDepth = useRecoilValue(liftDepthState);
    const specialRequirements = useRecoilValue(specialRequimentsState);
    const escortRequired = useRecoilValue(escortRequiredState);
    const escortDetail = useRecoilValue(escortDetailsState);
    const siteInductionRequired = useRecoilValue(siteInductionRequriedState);
    const equipmentLocation = useRecoilValue(equipmentLocationState);
    const accessStartTime = useRecoilValue(accessStartTimeState);
    const accessEndTime = useRecoilValue(accessEndTimeState);
    const removalReasons = useRecoilValue(removalReasonsState);
    // const removalReasonDetail = useRecoilValue(removalReasonDetailState);
    const outlet = useRecoilValue(outletState);
    const outletAddress = useRecoilValue(outletAddressState);
    const outletAddressChanged = useRecoilValue(outletAddressChangedState);
    const driver = useRecoilValue(driverState);
    const numberOfPeopleRequired = useRecoilValue(numberOfPeopleRequiredState);
    const ppeRequired = useRecoilValue(ppeRequiredState);
    // main contact
    const mainContactFirstName = useRecoilValue(mainContactFirstNameState);
    const mainContactLastName = useRecoilValue(mainContactLastNameState);
    const mainContactEmail = useRecoilValue(mainContactEmailState);
    const mainContactPhone = useRecoilValue(mainContactPhoneState);
    // CCEP contact
    const ccepContactFirstName = useRecoilValue(ccepContactFirstNameState);
    const ccepContactLastName = useRecoilValue(ccepContactLastNameState);
    const ccepContactEmail = useRecoilValue(ccepContactEmailState);
    const ccepContactPhone = useRecoilValue(ccepContactPhoneState);
    // Alternate contact
    const alternateContactFirstName = useRecoilValue(alternateContactFirstNameState);
    const alternateContactLastName = useRecoilValue(alternateContactLastNameState);
    const alternateContactEmail = useRecoilValue(alternateContactEmailState);
    const alternateContactPhone = useRecoilValue(alternateContactPhoneState);

    const unevenSurface = useRecoilValue(unevenSurfaceState);

    const formPayload = {
        move: {
            type: 'Removal',
            reasons: removalReasons == null ? [] : removalReasons!!.map(x => x.value)
        },
        loadingDock: loadingDockRequired ? {
            height: parseInt(loadingDockHeight),
            location: loadingDockLocation
        } : null,
        alternateParking: alternateParkingAvailable ? {
            details: alternateParkingDetails
        } : null,
        machineBoltedDown: machineBoltedDown!!,
        unevenSurface: unevenSurface!!,
        typeOfFlooring,
        doorway: !willMachineFitThroughDoorway ? {
            height: parseInt(doorwayHeight),
            width: parseInt(doorwayWidth)
        } : null,
        stairs: anyStairs ? {
            stepCount: parseInt(stairCount)
        } : null,
        anyRamp: anyRamp!!,
        lift: anyLift ? {
            height: parseInt(liftHeight),
            width: parseInt(liftWidth),
            depth: parseInt(liftDepth)
        } : null,
        specialRequirements,
        escort: escortRequired ? {
            details: escortDetail
        } : null,
        siteInductionRequired: siteInductionRequired!!,
        equipmentLocation,
        accessHours: {
            open: accessStartTime,
            close: accessEndTime
        },
        // removal: {
        //     reasons: removalReasons == null ? [] : removalReasons!!.map(x => x.value),
        //     details: removalReasonDetail  
        // },
        outlet: outlet!,
        updatedAddress: outletAddressChanged ? outletAddress : null,
        driver: driver!,
        numberOfPeopleRequired: numberOfPeopleRequired,
        ppeRequired: ppeRequired!,
        contacts: {
            main: {
                firstName: mainContactFirstName,
                lastName: mainContactLastName,
                email: mainContactEmail,
                phone: mainContactPhone
            },
            ccep: {
                firstName: ccepContactFirstName,
                lastName: ccepContactLastName,
                email: ccepContactEmail,
                phone: ccepContactPhone
            },
            alternate: {
                firstName: alternateContactFirstName,
                lastName: alternateContactLastName,
                email: alternateContactEmail,
                phone: alternateContactPhone
            }
        }
    };

    return formPayload;
}

export function useResetForm(): () => void {
    const resetLoadingDockRequired = useResetRecoilState(loadingDockRequiredState);
    const resetLoadingDockHeight = useResetRecoilState(loadingDockHeightState);
    const resetLoadingDockLocation = useResetRecoilState(loadingDockLocationState);
    const resetAlternateParkingAvailable = useResetRecoilState(alternateParkingAvailableState);
    const resetAlternateParkingDetails = useResetRecoilState(alternateParkingDetailsState);
    const resetMachineBoltedDown = useResetRecoilState(machineBoltedDownState);
    const resetTypeOfFlooring = useResetRecoilState(typeOfFlooringState);
    const resetAnyDoorWayIssues = useResetRecoilState(willMachineFitThroughDoorwayState);
    const resetDoorwayHeight = useResetRecoilState(doorwayHeightState);
    const resetDoorwayWidth = useResetRecoilState(doorwayWidthState);
    const resetAnyStairs = useResetRecoilState(anyStairsState);
    const resetStairCount = useResetRecoilState(stairCountState);
    const resetAnyLift = useResetRecoilState(anyLiftState);
    const resetAnyRamp = useResetRecoilState(anyRampState);
    const resetLiftHeight = useResetRecoilState(liftHeightState);
    const resetLiftWidth = useResetRecoilState(liftWidthState);
    const resetLiftDepth = useResetRecoilState(liftDepthState);
    const resetSpecialRequirements = useResetRecoilState(specialRequimentsState);
    const resetEscortRequired = useResetRecoilState(escortRequiredState);
    const resetEscortDetail = useResetRecoilState(escortDetailsState);
    const resetSiteInductionRequired = useResetRecoilState(siteInductionRequriedState);
    const resetEquipmentLocation = useResetRecoilState(equipmentLocationState);
    const resetAccessStartTime = useResetRecoilState(accessStartTimeState);
    const resetAccessEndTime = useResetRecoilState(accessEndTimeState);
    const resetRemovalReasons = useResetRecoilState(removalReasonsState);
    // const resetRemovalReasonDetail = useResetRecoilState(removalReasonDetailState);
    const resetOutlet = useResetRecoilState(outletState);
    const resetOutletAddress = useResetRecoilState(outletAddressState);
    const resetOutletAddressChanged = useResetRecoilState(outletAddressChangedState);
    const resetRemovalReasonsList = useResetRecoilState(removalReasonsListState);
    const resetDriver = useResetRecoilState(driverState);
    const resetNumberOfPeopleRequired = useResetRecoilState(numberOfPeopleRequiredState);
    const resetPpeRequired = useResetRecoilState(ppeRequiredState);
    // main contact
    const resetMainContactFirstName = useResetRecoilState(mainContactFirstNameState);
    const resetMainContactLastName = useResetRecoilState(mainContactLastNameState);
    const resetMainContactEmail = useResetRecoilState(mainContactEmailState);
    const resetMainContactPhone = useResetRecoilState(mainContactPhoneState);
    // CCEP contact
    const resetCcepContactFirstName = useResetRecoilState(ccepContactFirstNameState);
    const resetCcepContactLastName = useResetRecoilState(ccepContactLastNameState);
    const resetCcepContactEmail = useResetRecoilState(ccepContactEmailState);
    const resetCcepContactPhone = useResetRecoilState(ccepContactPhoneState);
    // Alternate contact
    const resetAlternateContactFirstName = useResetRecoilState(alternateContactFirstNameState);
    const resetAlternateContactLastName = useResetRecoilState(alternateContactLastNameState);
    const resetAlternateContactEmail = useResetRecoilState(alternateContactEmailState);
    const resetAlternateContactPhone = useResetRecoilState(alternateContactPhoneState);

    const resetUnevenSurface = useResetRecoilState(unevenSurfaceState);

    const clearForm = () => {
        resetLoadingDockRequired();
        resetLoadingDockHeight();
        resetLoadingDockLocation();
        resetAlternateParkingAvailable();
        resetAlternateParkingDetails();
        resetMachineBoltedDown();
        resetTypeOfFlooring();
        resetAnyDoorWayIssues();
        resetDoorwayHeight();
        resetDoorwayWidth();
        resetAnyStairs();
        resetStairCount();
        resetAnyLift();
        resetAnyRamp();
        resetLiftHeight();
        resetLiftWidth();
        resetLiftDepth();
        resetSpecialRequirements();
        resetEscortRequired();
        resetEscortDetail();
        resetSiteInductionRequired();
        resetEquipmentLocation();
        resetAccessStartTime();
        resetAccessEndTime();
        resetRemovalReasons();
        // resetRemovalReasonDetail();
        resetOutlet();
        resetOutletAddress();
        resetOutletAddressChanged();
        resetRemovalReasonsList();
        resetDriver();
        resetNumberOfPeopleRequired();
        resetPpeRequired();
        // main contact
        resetMainContactFirstName();
        resetMainContactLastName();
        resetMainContactEmail();
        resetMainContactPhone();
        // CCEP contact
        resetCcepContactFirstName();
        resetCcepContactLastName();
        resetCcepContactEmail();
        resetCcepContactPhone();
        // Alternate contact
        resetAlternateContactFirstName();
        resetAlternateContactLastName();
        resetAlternateContactEmail();
        resetAlternateContactPhone();

        resetUnevenSurface();
    };

    return clearForm;
}