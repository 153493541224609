import { useEffect, useState, useCallback } from "react";
import DisplayField from "./DisplayField";
import {MultiValue} from 'react-select'
import {useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import FieldGrouping from './FieldGrouping';
import TextAreaField from "./TextAreaField";
import { defaultRequiredBoolInputValidator, defaultRequiredStringInputValidator, isValid } from "./Validation";
import {
    accessStartTimeState, 
    accessEndTimeState,
    equipmentLocationState,
    validationFnState,
    outletAddressState,
    Outlet,
    outletState,
    outletAddressChangedState,
    removalReasonsState,
} from './SurveyAtoms'
import InputField from "./InputField";
import BoolField from "./BoolField";
import MultiSelectField, { SelectOption } from "./MultiSelectField";

const removalReasonsOptions: SelectOption[] = [
    {value: 'Lost to competitor', label: 'Lost to competitor'},
    {value: 'Range', label: 'Range'},
    {value: 'Service', label: 'Service'},
    {value: 'Financials', label: 'Financials'},
    {value: 'Business closing', label: 'Business closing'},
    {value: 'Landlord business request', label: 'Landlord business request'},
    {value: 'Other', label: 'Other'}
];

export default function OutletSurvey() {
    const setValidationFn = useSetRecoilState(validationFnState);

    const outletInfo = useRecoilValue<Outlet | null>(outletState);
    const [removalReasons, setRemovalReasons] = useRecoilState<MultiValue<SelectOption> | null>(removalReasonsState);
    const [equipmentLocation, setEquipmentLocation] = useRecoilState(equipmentLocationState);
    const [accessStartTime, setAccessStartTime] = useRecoilState(accessStartTimeState);
    const [accessEndTime, setAccessEndTime] = useRecoilState(accessEndTimeState);
    const [updatedAddress, setUpdatedAddress] = useRecoilState(outletAddressState);
    const [outletAddressChanged, setOutletAddressChanged] = useRecoilState(outletAddressChangedState);

    const [removalReasonsError, setRemovalReasonsError] = useState('');
    const [equipmentLocationError, setEquipmentLocationError] = useState('');
    const [startTimeError, setStartTimeError] = useState('');
    const [endTimeError, setEndTimeError] = useState('');
    const [updatedAddressError, setUpdatedAddressError] = useState('');
    const [outletAddressChangedError, setOutletAddressChangedError] = useState('');

    function onChangeWithLog(newValue: MultiValue<SelectOption>) {
        console.log(newValue);
        setRemovalReasons(newValue);
        // setRemovalReasonsError('');
        // removalReasonsValidationHandler();
    }

    useEffect(() => {
        setUpdatedAddressError('');
    }, [outletAddressChanged])

    const locationValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        equipmentLocation,
        'Outlet location must be defined',
        setEquipmentLocationError), [equipmentLocation]);


    const startTimeValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        accessStartTime,
        'Start time is required',
        setStartTimeError), [accessStartTime]);

    const endTimeValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        accessEndTime,
        'End time is required',
        setEndTimeError), [accessEndTime]);

    const outletAddressChangedValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(outletAddressChanged,
        'Please confirm whether the outlet address has changed',
        setOutletAddressChangedError), [outletAddressChanged]);

    const updatedAddressValidationHandler = useCallback(() => { 
        if (outletAddressChanged) {
            return defaultRequiredStringInputValidator(
                updatedAddress,
                'Please specify the new address',
                setUpdatedAddressError);
        }

        return true;
    }, [outletAddressChanged, updatedAddress]);

    const removalReasonsValidationHandler = useCallback(() => {       
        if (!removalReasons || 
            (removalReasons && removalReasons.length <= 0)) {
            setRemovalReasonsError('Please select at least one removal reason');
            return false;
        }

        return true;
    }, [removalReasons])

    const validatorFuncs = [
        locationValidationHandler,
        startTimeValidationHandler,
        endTimeValidationHandler,
        updatedAddressValidationHandler,
        outletAddressChangedValidator,
        removalReasonsValidationHandler
    ];

    const formValidator = useCallback((): boolean => {
            console.log('Outlet form validator run.');
            return isValid(validatorFuncs)
        },
        validatorFuncs);

    useEffect(() => {
        setValidationFn(() => {
            return formValidator
        });
    }, [formValidator]);

    if (outletInfo === undefined) {
        return(<></>);
    }

    return (        
        <div className="w-full max-w-lg px-4 pt-4 flex flex-col gap-4 mb-6">
            {
                outletInfo == null ? null
                :
                <>
                    <DisplayField name={'Outlet Name'} val={outletInfo.outletName}></DisplayField>
                    <DisplayField name={'Outlet Number'} val={outletInfo.outletNumber}></DisplayField>
                    <DisplayField name={'Plate Number'} val={outletInfo.plateNumber}></DisplayField>
                    <DisplayField name={'Equipment'} val={outletInfo.equipment}></DisplayField>
                    <DisplayField name={'Address'} val={outletInfo.address}></DisplayField>
                </>
            }
            
            {/* <DisplayField name={'Address'} val={outletInfo.address}></DisplayField> */}
            <BoolField question={'Has the address changed?'} 
            answer={outletAddressChanged} 
            onChange={setOutletAddressChanged}
            errorMessage={outletAddressChangedError}
            setErrorMessageHandler={setOutletAddressChangedError}
            required>
                <div className='mt-4'>
                <TextAreaField label='Updated Address'
                    value={updatedAddress}
                    rows={2}
                    onChange={setUpdatedAddress}
                    required={true}
                    errorMessage={updatedAddressError}
                    validationHandler={updatedAddressValidationHandler}
                    setErrorMessageHandler={setUpdatedAddressError} />
                </div>
            </BoolField>
            
            <FieldGrouping label='Access Hours' >
                <div className='grid grid-cols-2 gap-4'>
                    <InputField label="Open"
                    value={accessStartTime}
                    onChange={setAccessStartTime}
                    type="time"
                    required
                    errorMessage={startTimeError}
                    validationHandler={startTimeValidationHandler}
                    setErrorMessageHandler={setStartTimeError}
                    />
                    <InputField label="Close"
                    value={accessEndTime}
                    onChange={setAccessEndTime}
                    type="time"
                    required
                    errorMessage={endTimeError}
                    validationHandler={endTimeValidationHandler}
                    setErrorMessageHandler={setEndTimeError} />
                </div>
            </FieldGrouping>
            <TextAreaField label='Location of Equipment'
            placeholder='e.g. Outside main office entry'
            value={equipmentLocation}
            rows={3}
            onChange={setEquipmentLocation}
            required={true}
            errorMessage={equipmentLocationError}
            validationHandler={locationValidationHandler}
            setErrorMessageHandler={setEquipmentLocationError} />
            <MultiSelectField
            label="Reason(s) for removal"
            required={true}
            errorMessage={removalReasonsError}
            placeholder='Select reason(s) for removal'
            value={removalReasons}
            onChange={onChangeWithLog}
            options={removalReasonsOptions}
            validationHandler={removalReasonsValidationHandler}
            setErrorMessageHandler={setRemovalReasonsError} />        
    </div>);
}