import LandingButton from "../components/Buttons/LandingButton";
import QrCodeIcon from "../assets/img/qrCodeIcon.png"
import VSSIcon from "../assets/img/vssIcon.png"
import { useEffect } from "react";
import { atom, useSetRecoilState } from "recoil";

type Props = {
    enterQrPath: string,
    enterPlatePath: string,
    pageName: string,
    logo: string
}

export type Heading = {
    title: string,
    subTitle: string
}

export const headingState = atom<Heading>({
    key: 'heading',
    default: {
        title: '',
        subTitle: ''
    }
});

const Landing = ({enterQrPath, enterPlatePath, pageName, logo}: Props) => {
    const setHeading = useSetRecoilState(headingState);

    useEffect(() => {
        setHeading({
            title: pageName,
            subTitle: ''
        });
    }, []);

    return(

        <div className="flex flex-col flex-grow mt-0">

            <div className="flex flex-col lg:flex-row flex-grow lg:mx-auto lg:space-x-20">

                <div className="p-6 justify-center my-auto">
                    <img src={logo} className="w-72 md:w-2/5 lg:w-96 h-auto mx-auto" alt="logo"></img>
                </div>

                <div className="h-96 w-[2px] bg-slate-200 my-auto hidden lg:block" />

                <div className="flex my-auto space-x-6 justify-center lg:justify-start">    
                    <LandingButton image={QrCodeIcon} text="Scan QR Code" to={enterQrPath}></LandingButton>
                    <LandingButton image={VSSIcon} text="Enter Plate Number" to={enterPlatePath}></LandingButton>
                </div>

            </div>
            
            {/* <div className="text-center p-6 flex flex-col">
                <p className="font-bold">DEV BUILD</p>
                <p>18<sup>th</sup> January, 2022</p>
            </div> */}

        </div>

    )

}

export default Landing;