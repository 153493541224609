import InputField from './InputField';
import BoolField from './BoolField';
import TextAreaField from './TextAreaField';
import UnitInputField from './UnitInputField';
import FieldGrouping from './FieldGrouping';
import {useRecoilState, useSetRecoilState} from 'recoil';
import { 
    loadingDockRequiredState,
    loadingDockLocationState,
    loadingDockHeightState,
    alternateParkingAvailableState,
    alternateParkingDetailsState,
    machineBoltedDownState,
    typeOfFlooringState,
    willMachineFitThroughDoorwayState,
    doorwayHeightState,
    doorwayWidthState,
    anyStairsState,
    stairCountState,
    anyLiftState,
    anyRampState,
    liftHeightState,
    liftWidthState,
    liftDepthState,
    validationFnState,
    numberOfPeopleRequiredState,
    unevenSurfaceState,
} from './SurveyAtoms';
import { useCallback, useEffect, useState } from 'react';
import { defaultRequiredBoolInputValidator, defaultRequiredIntInputValidator, defaultRequiredStringInputValidator, isValid } from './Validation';
import SelectField from './SelectField';

export default function AccessibilitySurvey() {
    const setValidationFn = useSetRecoilState(validationFnState);

    const [loadingDockLocation, setLoadingDockLocation] = useRecoilState(loadingDockLocationState);
    const [loadingDockRequired, setLoadingDockRequired] = useRecoilState(loadingDockRequiredState);
    const [loadingDockHeight, setLoadingDockHeight] = useRecoilState(loadingDockHeightState);
    const [alternateParkingAvailable, setAlternateParkingAvailable] = useRecoilState(alternateParkingAvailableState);
    const [alternateParkingDetails, setAlternateParkingDetails] = useRecoilState(alternateParkingDetailsState);
    const [isMachineBoltedDown, setIsMachineBoltedDown] = useRecoilState(machineBoltedDownState);
    const [unevenSurface, setUnevenSurface] = useRecoilState(unevenSurfaceState);
    const [typeOfFlooring, setTypeOfFlooring] = useRecoilState(typeOfFlooringState);
    const [willMachineFitThroughDoorway, setAnyDoorwayIssues] = useRecoilState(willMachineFitThroughDoorwayState);
    const [doorHeight, setDoorHeight] = useRecoilState(doorwayHeightState);
    const [doorWidth, setDoorWidth] = useRecoilState(doorwayWidthState);
    const [anyStairs, setAnyStairs] = useRecoilState(anyStairsState);
    const [numberOfStairs, setNumberOfStairs] = useRecoilState(stairCountState);
    const [anyLift, setAnyLift] = useRecoilState(anyLiftState);
    const [anyRamp, setAnyRamp] = useRecoilState(anyRampState);
    const [liftHeight, setLiftHeight] = useRecoilState(liftHeightState);
    const [liftWidth, setLiftWidth] = useRecoilState(liftWidthState);
    const [liftDepth, setLiftDepth] = useRecoilState(liftDepthState);
    const [numberOfPeopleRequired, setNumberOfPeopleRequired] = useRecoilState(numberOfPeopleRequiredState);

    // errors
    const [loadingDockLocationError, setLoadingDockLocationError] = useState('');
    const [loadingDockRequiredError, setLoadingDockRequiredError] = useState('');
    const [loadingDockHeightError, setLoadingDockHeightError] = useState('');
    const [alternateParkingAvailableError, setAlternateParkingAvailableError] = useState('');
    const [alternativeParkingDetailsError, setAlternativeParkingDetailsError] = useState('');
    const [machineBoltedDownError, setMachineBoltedDownError] = useState('');
    const [unevenSurfaceError, setUnevenSurfaceError] = useState('');
    const [typeOfFlooringError, setTypeOfFlooringError] = useState('');
    const [doorwayIssuesError, setDoorwayIssuesError] = useState('');
    const [doorHeightError, setDoorHeightError] = useState('');
    const [doorWidthError, setDoorWidthError] = useState('');
    const [anyStairsError, setAnyStairsError] = useState('');
    const [numberOfStairsError, setNumberOfStairsError] = useState('');
    const [anyRampError, setAnyRampError] = useState('');
    const [anyLiftError, setAnyLiftError] = useState('');
    const [liftHeightError, setLiftHeightError] = useState('');
    const [liftWidthError, setLiftWidthError] = useState('');
    const [liftDepthError, setLiftDepthError] = useState('');
    const [numberOfPeopleRequiredError, setNumberOfPeopleRequiredError] = useState('');

    useEffect(() => {
        setAlternativeParkingDetailsError('')
    }, [alternateParkingAvailable]);

    useEffect(() => {
        setDoorHeightError('');
        setDoorWidthError('');
    }, [willMachineFitThroughDoorway]);

    useEffect(() => {
        setNumberOfStairsError('');
    }, [anyStairs]);

    useEffect(() => {
        setLoadingDockHeightError('');
        setLoadingDockLocationError('');
    }, [loadingDockRequired]);

    useEffect(() => {
        setLiftHeightError('');
        setLiftWidthError('');
        setLiftDepthError('');
    }, [anyLift])

    const loadingDockRequiredValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(loadingDockRequired,
        'Please specify whether a loading dock is required',
        setLoadingDockRequiredError),
        [loadingDockRequired]);

    const loadingDockHeightValidationHandler = useCallback(() => {
        if (loadingDockRequired) {
            return defaultRequiredIntInputValidator(
                loadingDockHeight,
                'Loading dock height',
                setLoadingDockHeightError);   
        }

        return true;
    }, [loadingDockRequired, loadingDockHeight]);


    const loadingDockLocationValidationHandler = useCallback(() => {
        if (loadingDockRequired) {
            return defaultRequiredStringInputValidator(
                loadingDockLocation,
                'Please provide details on loading dock location',
                setLoadingDockLocationError);   
        }

        return true;
    }, [loadingDockRequired, loadingDockLocation]);

    const doorHeightValidationHandler = useCallback(() => { 
        if (!willMachineFitThroughDoorway) {
            return defaultRequiredIntInputValidator(
                doorHeight,
                'Door height',
                setDoorHeightError);
        }

        return true;
    }, [willMachineFitThroughDoorway, doorHeight]);

    const doorWidthValidationHandler = useCallback(() => { 
        if (!willMachineFitThroughDoorway) {
            return defaultRequiredIntInputValidator(
                doorWidth,
                'Door width',
                setDoorWidthError);
        }

        return true;
    }, [willMachineFitThroughDoorway, doorWidth]);

    const numberOfStairsValidationHandler = useCallback(() => { 
        if (anyStairs) {
            return defaultRequiredIntInputValidator(
                numberOfStairs,
                'Number of steps',
                setNumberOfStairsError);
        }

        return true;
    }, [anyStairs, numberOfStairs]);

    const typeOfFlooringValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        typeOfFlooring,
        'Please specify type of flooring',
        setTypeOfFlooringError),
        [typeOfFlooring]);

    const alternativeParkingDetailsValidationHandler = useCallback(() => { 
        if (alternateParkingAvailable &&
            !alternateParkingDetails) {
            setAlternativeParkingDetailsError('Please provide escort details');
            return false;
        }

        return true;
    }, [alternateParkingAvailable, alternateParkingDetails]);

    const liftHeightValidationHandler = useCallback(() => { 
        if (anyLift) {
            return defaultRequiredIntInputValidator(
                liftHeight,
                'Lift height',
                setLiftHeightError);
        }

        return true;
    }, [anyLift, liftHeight]);

    const liftWidthValidationHandler = useCallback(() => { 
        if (anyLift) {
            return defaultRequiredIntInputValidator(
                liftWidth,
                'Lift width',
                setLiftWidthError);
        }

        return true;
    }, [anyLift, liftWidth]);

    const liftDepthValidationHandler = useCallback(() => { 
        if (anyLift) {
            return defaultRequiredIntInputValidator(
                liftDepth,
                'Lift depth',
                setLiftDepthError);
        }

        return true;
    }, [anyLift, liftDepth]);

    const alternateParkingAvailableValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(alternateParkingAvailable,
        'Please specify whether alternate parking is available',
        setAlternateParkingAvailableError), 
        [alternateParkingAvailable]);

    const machineBoltedDownValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(isMachineBoltedDown,
        'Please specify whether machine is bolted down',
        setMachineBoltedDownError
        ),[isMachineBoltedDown]);

    const unevenSurfaceValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(unevenSurface,
        'Please specify whether surface is uneven',
        setUnevenSurfaceError
        ),[unevenSurface]);

    const doorwayIssuesValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(willMachineFitThroughDoorway,
        'Please confirm whether equipment will fit through all required doorways',
        setDoorwayIssuesError), [willMachineFitThroughDoorway]);

    const anyStairsValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(anyStairs,
        'Please specify whether there are stairs',
        setAnyStairsError),
        [anyStairs]);

    const anyRampValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(anyRamp,
        'Please specify whether there is a ramp',
        setAnyRampError), 
        [anyRamp]);

    const anyLiftValidator = useCallback(() => 
        defaultRequiredBoolInputValidator(anyLift,
        'Please specify whether there is a lift',
        setAnyLiftError), [anyLift]);

    const numberOfPeopleRequiredValidator = useCallback(() => 
        defaultRequiredStringInputValidator(numberOfPeopleRequired,
        'Please specify the number of people required',
        setNumberOfPeopleRequiredError), [numberOfPeopleRequired]);

    const validationHandlers = [loadingDockLocationValidationHandler,
        loadingDockRequiredValidator,
        loadingDockHeightValidationHandler,
        alternateParkingAvailableValidator,
        alternativeParkingDetailsValidationHandler,
        machineBoltedDownValidator,
        unevenSurfaceValidator,
        typeOfFlooringValidationHandler,
        doorwayIssuesValidator,
        doorHeightValidationHandler,
        doorWidthValidationHandler,
        anyStairsValidator,
        numberOfStairsValidationHandler,
        anyRampValidator,
        anyLiftValidator,
        liftHeightValidationHandler,
        liftWidthValidationHandler,
        liftDepthValidationHandler,
        numberOfPeopleRequiredValidator
    ];

    const formValidator = useCallback((): boolean => {
        console.log('accessibilitySurvey form validator called');
        console.trace();
        return isValid(validationHandlers);
    }, validationHandlers);

    useEffect(() => {
        setValidationFn(() => formValidator);
    }, [formValidator]);

    return (
        <div className="w-full max-w-lg px-4 flex flex-col gap-6 mb-4">
            <BoolField question='Is the use of a loading dock required?' answer={loadingDockRequired} 
            onChange={setLoadingDockRequired}
            errorMessage={loadingDockRequiredError}
            setErrorMessageHandler={setLoadingDockRequiredError}
            required>
                <div className='mt-4'>
                    <FieldGrouping label='Loading Dock'>
                        <TextAreaField required
                            label={'Location'} rows={3} 
                            value={loadingDockLocation} 
                            onChange={setLoadingDockLocation}
                            errorMessage={loadingDockLocationError}
                            validationHandler={loadingDockLocationValidationHandler}
                            setErrorMessageHandler={setLoadingDockLocationError} />
                        <div className='grid grid-cols-2 gap-4'>
                            <UnitInputField required
                            label={'Height'}
                            value={loadingDockHeight}
                            type={'number'}
                            onChange={setLoadingDockHeight}
                            unit={'mm'}
                            errorMessage={loadingDockHeightError}
                            validationHandler={loadingDockHeightValidationHandler}
                            setErrorMessageHandler={setLoadingDockHeightError} />
                        </div>
                    </FieldGrouping>
                </div>
            </BoolField>
            <BoolField question={'Alternative Parking Available?'} 
            answer={alternateParkingAvailable} 
            onChange={setAlternateParkingAvailable}
            errorMessage={alternateParkingAvailableError}
            setErrorMessageHandler={setAlternateParkingAvailableError}
            required>
                <div className='mt-4'>
                <TextAreaField required
                label={'Provide alternative parking details'} rows={3} 
                value={alternateParkingDetails} 
                onChange={setAlternateParkingDetails}
                errorMessage={alternativeParkingDetailsError}
                validationHandler={alternativeParkingDetailsValidationHandler}
                setErrorMessageHandler={setAlternativeParkingDetailsError} />
                </div>
            </BoolField>
            <BoolField question={'Is the machine bolted down?'} answer={isMachineBoltedDown}
            onChange={setIsMachineBoltedDown} 
            errorMessage={machineBoltedDownError}
            setErrorMessageHandler={setMachineBoltedDownError}
            required/>
            <BoolField question={'Uneven surface?'} answer={unevenSurface}
            onChange={setUnevenSurface} 
            errorMessage={unevenSurfaceError}
            setErrorMessageHandler={setUnevenSurfaceError}
            required/>
            <TextAreaField required
            value={typeOfFlooring} 
            label='Type of Flooring'
            rows={2}
            onChange={setTypeOfFlooring}
            errorMessage={typeOfFlooringError}
            validationHandler={typeOfFlooringValidationHandler}
            setErrorMessageHandler={setTypeOfFlooringError} />
            <BoolField question="Will equipment fit through all required doorways?" answer={willMachineFitThroughDoorway} 
            onChange={setAnyDoorwayIssues}
            errorMessage={doorwayIssuesError}
            setErrorMessageHandler={setDoorwayIssuesError}
            activeTrue={false}
            messageTag="(If unsure select 'No' and provide measurements)"
            required>
                <div className='mt-4'>
                    <FieldGrouping label='Doorway'>
                        <div className='grid grid-cols-2 gap-4'>
                            <UnitInputField 
                            label={'Height'}
                            value={doorHeight}
                            type={'number'}
                            onChange={setDoorHeight}
                            unit={'mm'}
                            required
                            errorMessage={doorHeightError}
                            validationHandler={doorHeightValidationHandler}
                            setErrorMessageHandler={setDoorHeightError} />
                            <UnitInputField 
                            label={'Width'}
                            value={doorWidth}
                            type={'number'}
                            onChange={setDoorWidth}
                            unit={'mm'}
                            required
                            errorMessage={doorWidthError}
                            validationHandler={doorWidthValidationHandler}
                            setErrorMessageHandler={setDoorWidthError} />
                        </div>
                    </FieldGrouping>
                </div>
            </BoolField>
            <BoolField question='Are there stairs?' answer={anyStairs} 
            onChange={setAnyStairs}
            errorMessage={anyStairsError}
            setErrorMessageHandler={setAnyStairsError}
            required>
                <div className='mt-4'>
                <InputField required label='Number of steps in stairs' 
                value={numberOfStairs} 
                onChange={setNumberOfStairs} type='number'
                errorMessage={numberOfStairsError}
                validationHandler={numberOfStairsValidationHandler}
                setErrorMessageHandler={setNumberOfStairsError} />
                </div>
            </BoolField>
            <BoolField question='Is there a ramp to be utilized?' answer={anyRamp}
            onChange={setAnyRamp}
            errorMessage={anyRampError}
            setErrorMessageHandler={setAnyRampError}
            required />
            <BoolField question='Is there a Lift on site?' answer={anyLift}
            onChange={setAnyLift}
            errorMessage={anyLiftError}
            setErrorMessageHandler={setAnyLiftError}
            required >
                <div className='mt-4'>
                    <FieldGrouping label='Lift'>
                        <div className='grid grid-cols-2 gap-4'>
                            <UnitInputField required
                            label={'Height'}
                            value={liftHeight}
                            type={'number'}
                            onChange={setLiftHeight}
                            unit={'mm'}
                            errorMessage={liftHeightError}
                            validationHandler={liftHeightValidationHandler}
                            setErrorMessageHandler={setLiftHeightError} />
                            <UnitInputField required
                            label={'Width'}
                            value={liftWidth}
                            type={'number'}
                            onChange={setLiftWidth}
                            unit={'mm'}
                            errorMessage={liftWidthError}
                            validationHandler={liftWidthValidationHandler}
                            setErrorMessageHandler={setLiftWidthError} />
                            <UnitInputField required
                            label={'Depth'}
                            value={liftDepth}
                            type={'number'}
                            onChange={setLiftDepth}
                            unit={'mm'}
                            errorMessage={liftDepthError}
                            validationHandler={liftDepthValidationHandler}
                            setErrorMessageHandler={setLiftDepthError} />
                        </div>
                    </FieldGrouping>
                </div>
            </BoolField>
            <SelectField 
                label='Number of people required'
                value={numberOfPeopleRequired}
                onChange={setNumberOfPeopleRequired}
                options={['2', '4']}
                required
                errorMessage={numberOfPeopleRequiredError}
                setErrorMessageHandler={setNumberOfPeopleRequiredError}
                />
      </div>
    );
}