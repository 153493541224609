export type Props = {
    message: string
}

export default function MessageTag({message}: Props) {
    return (
        <label 
            className="form-label 
            inline-block mb-2 
            text-gray-500
            text-sm
            italic"
            >{message}
        </label>
    );
}