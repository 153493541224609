import { useMsal } from "@azure/msal-react";
import { AnimatePresence } from "framer-motion";
import React, { createRef, useRef, useState } from "react";
import { EquipmentActionType } from "../../context/equipment/EquipmentReducer";
import { useEquipmentStateContext } from "../../context/equipment/EquipmentState";
import { UnauthorizedError } from "../../exceptions/UnauthorizedError";
import { getIdToken, loginRedirect } from "../../helpers/ApiUtils";
import { uploadImage } from "../../helpers/ImageUploadUtils";
import { UploadButton } from "../Buttons/UploadButton";
import { DisplayErrorModal } from "../Containers/DisplayErrorModal";
import { ImagePreviewContainer } from "../Containers/ImagePreviewContainer";
import { DisplayMessageError, MessageType } from "../Messages/Message";
//import { ImageDisplay } from "../Displays/ImageDisplay";

type props = {
    setReportLoading: React.Dispatch<React.SetStateAction<boolean>>
};

const MediaCard = ({setReportLoading}: props) => {

    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const [errorModalMessage, setErrorModalMessage] = useState('');

    // Msal hook
    const {instance, accounts} = useMsal();

    const {state: equipmentState, dispatch: equipmentDispatch} = useEquipmentStateContext();

    const fileInputRef = createRef<HTMLInputElement>();

    const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setErrorModalOpen(false);

        try {
            setReportLoading(true);

            //Null safety check
            if(!e.target.files || !e.target.files[0]) {
                console.log('No target file')
                return;
            }

            const token = await getIdToken(instance, accounts[0])

            const response = await uploadImage(e.target.files[0], equipmentState.equipment!.plate, token)

            console.log(response)

            equipmentDispatch(
            {
                type: EquipmentActionType.ADD_PHOTO,
                payload: {photoUrl: response.imageUrl}
            })

            // console.log(equipmentState)
        } catch (err) {
            if (err instanceof UnauthorizedError) {
                await loginRedirect(instance, accounts[0]);
            }
            else if (err instanceof DisplayMessageError) {
                // display error message modal
                const displayError = err as DisplayMessageError;

                setErrorModalMessage(displayError.message);
            } else {
                console.log("Unhandled exception: " + err)
                // display unhandled error message modal
                setErrorModalMessage('An unexpected error occured.');
            }

            console.log('error modal opened')
            setErrorModalOpen(true);
        } finally {
            setReportLoading(false);
        }

    };

    // handler to reset file selection so that the handler is retriggered
    const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const element = event.target as HTMLInputElement
        element.value = ''
    }

    return(
        <div className="flex flex-col p-4 bg-gradient-to-b from-gray-100 mx-auto to-gray-200 shadow-md text-gray-600 text-center rounded-lg w-full md:w-[28rem] justify-center">

                <h1 className="text-2xl font-semibold my-4">Media</h1>

                <div className='flex mx-auto mb-4'>

                    <input accept="image/*" type="file" ref={fileInputRef} className="hidden" onChange={handleFileInput} onClick={onInputClick} />

                </div>

                <div className="flex justify-center space-x-4 align-bottom">
                    {
                        equipmentState.photos && equipmentState.photos.map(
                            (image) => { 
                                return <ImagePreviewContainer key={image} imgUrl={image} deleteCallback={
                                    (url: string) => equipmentDispatch({type: EquipmentActionType.REMOVE_PHOTO, payload: {photoUrl: url}})
                                }
                                /> 
                            }
                        )
                    }
                    {
                    (!equipmentState.photos || (equipmentState.photos && equipmentState.photos.length < 4)) && <button onClick={() => fileInputRef.current!.click()}>
                        <div className="mt-auto">
                            <div className="w-[4.5rem]  h-[4.5rem] bg-gray-300 rounded-lg shadow-inner flex">
                                <svg xmlns="http://www.w3.org/2000/svg" className="fill-current text-gray-500 mx-auto my-auto" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/>
                                </svg>
                            </div>
                        </div>
                    </button>
                    }
                    
                </div>

                <h3 className="mt-4 text-lg">Upload Media</h3> 


                
                {errorModalOpen && <DisplayErrorModal key="imageUploadErrorModal" message={errorModalMessage} closeCallback={() => setErrorModalOpen(false)} />}
                
        </div>
       
    );

}

export default MediaCard;