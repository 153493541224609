import Label from './Label';
import ValidationFieldWrapper from './ValidatedFieldWrapper';

export type Props = {
    label: string,
    value: string,
    type?: string,
    unit: string,
    onChange(val: string): void,
    required?: boolean,
    errorMessage?: string,
    validationHandler?(): void,
    setErrorMessageHandler?(val: string): void
}

export default function UnitInputField({label, 
    unit, value, onChange, type, required,
    errorMessage,
    validationHandler,
    setErrorMessageHandler
}: Props) {
    function onChangeImpl(e: React.ChangeEvent<HTMLInputElement>) {
        if (setErrorMessageHandler) {
            setErrorMessageHandler('');
        }
        
        onChange(e.target.value);
    }

    function onBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (validationHandler) {
            validationHandler();
        }
    }

    return (
        <ValidationFieldWrapper
            label={label}
            required={required}
            errorMessage={errorMessage}>
            <div className='flex'>
                <input type={type ?? 'text'} 
                value={value}
                onChange={onChangeImpl}
                onBlur={onBlur}
                className="appearance-none 
                block 
                w-full 
                text-gray-700 
                border 
                border-gray-200 
                rounded-l 
                py-3 px-4 
                leading-tight 
                focus:outline-none 
                focus:bg-white 
                focus:border-gray-500" />
                <div className='bg-gray-200 border border-gray-200 rounded-r flex items-center'>
                    <div className='px-2' style={{height: 'min-content'}}>{unit}</div>
                </div>
            </div>
        </ValidationFieldWrapper>);
}