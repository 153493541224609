import { CategoryEntry, CategoryStoreData } from "../context/category/CategoryState";
import { EquipmentRating, EquipmentStoreData } from "../context/equipment/EquipmentState";

/**
 * Calculates the numerical rating of a given EquipmentRating.
 * 
 * @param {EquipmentRating} ratingData - The rating data to calculate the rating from.
 * @param {CategoryEntry} categoryData - The category used to search for reason codes.
 * @returns - A number from 0.0 to 1.0 representing the rating of the given EquipmentRating.
 */
export const calculateRating = (ratingData: EquipmentRating, categoryData: CategoryEntry): number => {

    //Use rating associated with exemption, should both be provided.
    if(!ratingData.flag && ratingData.exemption && ratingData.exemption.rating) return ratingData.exemption.rating;

    const value = (ratingData.flag) ? 1.0 : 
        (!categoryData.options) ? 0.0 : 
        categoryData.options.find( option => option.key === ratingData.reasonCode)?.rating
    ;

    return (value) ? value : 0.0;

}

// /**
//  * Checks to ensure that all equipment data has been loaded
//  * 
//  * @param {EquipmentStoreData} equipmentData - The data to check.
//  * @returns - True if the data has been loaded (i.e. all fields are present), false otherwise
//  */
// export const isEquipmentLoaded = (equipmentData: EquipmentStoreData): boolean => {
//     return(
//         typeof equipmentData.ratings !== undefined && 
//         typeof equipmentData.date !== undefined &&
//         typeof equipmentData.outlet !== undefined &&
//         typeof equipmentData.equipment !== undefined
//     )
        
// }

// /**
//  * Checks to ensure tha all category data has been loaded
//  * 
//  * @param {CategoryStoreData} categoryData - The data to check 
//  * @returns - True if the data has been loaded (i.e. all fields are present), false otherwise
//  */
// export const isCategoryLoaded = (categoryData: CategoryStoreData): boolean => 
//     typeof categoryData.categories !== undefined;