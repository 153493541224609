import './App.css';
import { Routes, Route, useLocation} from "react-router-dom";
import Landing, { headingState } from './pages/Landing'
import PlateEntry from './pages/PlateEntry';
import QREntry from './pages/QREntry';
import Report from './pages/Report';
import Submit from './pages/Submit';
import NavigationButton from './components/Buttons/NavigationButton';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import FiveStarLogo from "./assets/img/fiveStarLogo.png"//"/assets/img/logoStub.png"
// import CloseSiteLogo from "./assets/img/logoStub.png"
import Survey from './pages/Survey';
import SubmitCloseSiteSurvey from './pages/SubmitCloseSiteSurvey';
import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';

const AuthenticatedContent = () => {

  //Location hook for routing
  let location = useLocation();
  
  return (
    <div>
      <div className="flex flex-col flex-1 md:overflow-hidden">

        <div className="flex flex-col flex-grow items-center">
          
              <Routes location={location}>
                <Route path="/" element={<Landing logo={FiveStarLogo} pageName='Five Star' enterPlatePath='/enterplate' enterQrPath='enterqr'/>}></Route>
                <Route path="/enterplate" element={<PlateEntry homeRoute='/' baseRoute='/report' />}></Route>
                <Route path="/enterqr" element={<QREntry homeRoute='/' baseRoute='/report' />}></Route>
                <Route path="/report" element={<Report />}></Route>
                <Route path="/submit" element={<Submit />}></Route>
                <Route path="/close-site" element={<Landing logo={FiveStarLogo} pageName='Close Site Survey' enterPlatePath='/close-site/enterplate' enterQrPath='/close-site/enterqr'/>}></Route>
                <Route path="/close-site/survey" element={<Survey />}></Route>
                <Route path="/close-site/submit" element={<SubmitCloseSiteSurvey />}></Route>
                <Route path="/close-site/enterplate" element={<PlateEntry homeRoute='/close-site' baseRoute='/close-site/survey' />}></Route>
                <Route path="/close-site/enterqr" element={<QREntry homeRoute='/close-site' baseRoute='/close-site/survey' />}></Route>
              </Routes>
            
        </div>
        
      </div>
      
    </div>
  );
}

const UnauthenticatedContent = () => {
  const {instance} = useMsal()

  const location = useLocation();

  const pathContainsCloseSite = location.pathname.includes('close-site');

  const setHeading = useSetRecoilState(headingState);

    useEffect(() => {
        if (pathContainsCloseSite) {
            setHeading({
                title: 'Close Site Survey',
                subTitle: ''
            });
        } else {
            setHeading({
                title: 'Five Star',
                subTitle: ''
            });
        }
        
    }, []);


  return (
    <div className="flex flex-col flex-grow items-center">
      <div className='max-w-lg'>

      {pathContainsCloseSite ? null : 
        <div className="flex flex-col lg:flex-row flex-grow lg:mx-auto lg:space-x-20">
            <div className="p-2 justify-center my-auto">
                <img src={FiveStarLogo} className="w-72 md:w-2/5 lg:w-96 h-auto mx-auto" alt="logo"></img>
            </div>
        </div>}

        <div className="text-center p-6 flex flex-col self-stretch">
            <p className="font-bold mb-4">Please sign in to continue</p>
            <NavigationButton text="Sign In" disabled={false} dominant={false} action={() => {instance.loginRedirect()}}/>
        </div>
      </div>
    </div>
  );
}

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
 export const App = () => {
  return (
      <div className="App">
          <AuthenticatedTemplate>
            <AuthenticatedContent />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <UnauthenticatedContent />
          </UnauthenticatedTemplate>
      </div>
  );
};

export default App;
