import ValidationFieldWrapper from "./ValidatedFieldWrapper";
import Select, {MultiValue, ActionMeta, StylesConfig, GroupBase} from 'react-select'
import { useEffect } from "react";

export type SelectOption = {
    value: string,
    label: string
}

export type Props = {
    label: string,
    required?: boolean,
    errorMessage?: string,
    options: SelectOption[],
    value: MultiValue<SelectOption> | null,
    onChange(val: MultiValue<SelectOption>): void,
    placeholder?: string,
    validationHandler?(): void,
    setErrorMessageHandler?(val: string): void
};

  const customOptionStyles: React.CSSProperties = {
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    // padding: '0.1rem'
  };

export default function MultiSelectField(
    {
        label, 
        required, 
        errorMessage,
        options,
        value,
        onChange,
        placeholder,
        validationHandler,
        setErrorMessageHandler
    }: Props)
{
    const customControlStyles: React.CSSProperties = {
        // color: "white",
        borderColor: errorMessage ? "rgb(239, 68, 68)" : "rgb(229, 231, 235)",
        // height: '3rem'
        // padding: '0.15rem',
        minHeight: '3rem'
      };

      const customStyles: StylesConfig<SelectOption, true> = {
        multiValue: (provided, state) => {
            return {
                ...provided,
                ...customOptionStyles
            }
        },
        control: (provided, state) => {
            return {
                ...provided,
                ...customControlStyles,
                // borderColor: state.isFocused ? 'rgb(229, 231, 235)' : 'rgb(229, 231, 235)',
                // outline: state.isFocused ? 'rgb(229, 231, 235)' : 'rgb(229, 231, 235)'
            }
        }
    };

    useEffect(() => {
        if (setErrorMessageHandler) {
            setErrorMessageHandler('');
        }
    }, [value]);

    function onChangeImpl(newValue: MultiValue<SelectOption>, actionMeta: ActionMeta<SelectOption>) {
        onChange(newValue);
    }

    function onBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (validationHandler) {
            validationHandler();
        }
    }

    return (<ValidationFieldWrapper
        label={label}
        required={required}
        errorMessage={errorMessage}
        >
            <Select
            placeholder={placeholder}
            value={value}
            onChange={onChangeImpl}
            options={options}
            isMulti
            styles={customStyles}
            onBlur={onBlur}
            />
        </ValidationFieldWrapper>);
}