const InfoSVG = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="19.958" height="19.955" viewBox="0 0 19.958 19.955" className="float-left mr-2">
        <g data-type="innerShadowGroup">
            <path id="Path_3-2" data-name="Path 3" d="M8.173-255.761A9.836,9.836,0,0,0,2.831-252.9a9.15,9.15,0,0,0-1.778,2.441A9.559,9.559,0,0,0,0-245.9a9.561,9.561,0,0,0,2.987,7.151,9.149,9.149,0,0,0,2.441,1.778,9.559,9.559,0,0,0,4.554,1.053,9.559,9.559,0,0,0,4.554-1.053,9.149,9.149,0,0,0,2.441-1.778,9.791,9.791,0,0,0,2.87-5.4,13.786,13.786,0,0,0,0-3.494,10.321,10.321,0,0,0-1.723-4.11,13.077,13.077,0,0,0-2.379-2.355,10.324,10.324,0,0,0-4.016-1.653A14.278,14.278,0,0,0,8.173-255.761Zm2.667,1.513a8.8,8.8,0,0,1,3.8,1.38,10.676,10.676,0,0,1,2.067,1.973,10.257,10.257,0,0,1,1.388,2.846,8.125,8.125,0,0,1-.577,5.771,8.406,8.406,0,0,1-6.777,4.726,8.5,8.5,0,0,1-5.412-1.388,10.675,10.675,0,0,1-2.067-1.973,10.257,10.257,0,0,1-1.388-2.846,8.177,8.177,0,0,1,.507-5.631A7.476,7.476,0,0,1,3.9-251.628a7.841,7.841,0,0,1,2.457-1.809A7.869,7.869,0,0,1,10.84-254.248Z" transform="translate(0 255.876)" fill="#39bb30"/>
            <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_3)">
            <path id="Path_3-3" data-name="Path 3" d="M8.173-255.761A9.836,9.836,0,0,0,2.831-252.9a9.15,9.15,0,0,0-1.778,2.441A9.559,9.559,0,0,0,0-245.9a9.561,9.561,0,0,0,2.987,7.151,9.149,9.149,0,0,0,2.441,1.778,9.559,9.559,0,0,0,4.554,1.053,9.559,9.559,0,0,0,4.554-1.053,9.149,9.149,0,0,0,2.441-1.778,9.791,9.791,0,0,0,2.87-5.4,13.786,13.786,0,0,0,0-3.494,10.321,10.321,0,0,0-1.723-4.11,13.077,13.077,0,0,0-2.379-2.355,10.324,10.324,0,0,0-4.016-1.653A14.278,14.278,0,0,0,8.173-255.761Zm2.667,1.513a8.8,8.8,0,0,1,3.8,1.38,10.676,10.676,0,0,1,2.067,1.973,10.257,10.257,0,0,1,1.388,2.846,8.125,8.125,0,0,1-.577,5.771,8.406,8.406,0,0,1-6.777,4.726,8.5,8.5,0,0,1-5.412-1.388,10.675,10.675,0,0,1-2.067-1.973,10.257,10.257,0,0,1-1.388-2.846,8.177,8.177,0,0,1,.507-5.631A7.476,7.476,0,0,1,3.9-251.628a7.841,7.841,0,0,1,2.457-1.809A7.869,7.869,0,0,1,10.84-254.248Z" transform="translate(0 255.88)" fill="#fff"/>
            </g>
        </g>
        <path id="Path_4" data-name="Path 4" d="M112.548-203.844a1.071,1.071,0,0,0,.343,1.942,1.25,1.25,0,0,0,1.146-.3c.218-.226.242-.289.242-.756,0-.491-.008-.523-.289-.78a.827.827,0,0,0-.741-.265A1.266,1.266,0,0,0,112.548-203.844Z" transform="translate(-103.323 208.046)" fill="#39bb30"/>
        <path id="Path_5" data-name="Path 5" d="M112.4-162.8a1.169,1.169,0,0,0-.32.484c-.047.179-.078,1.638-.078,3.774,0,3.034.016,3.533.125,3.806a.912.912,0,0,0,.967.624.979.979,0,0,0,1.022-.569c.133-.3.148-.554.148-3.876s-.016-3.572-.148-3.876a.988.988,0,0,0-1.053-.569A.831.831,0,0,0,112.4-162.8Z" transform="translate(-103.266 170.243)" fill="#39bb30"/>
        </svg>

    )
}

export default InfoSVG;