import React, { useState } from "react";
import Label from './Label';
import ValidationFieldWrapper from "./ValidatedFieldWrapper";

/**
 * Bool control properties.
 */
export type Props = {
    question: string;
    answer: boolean | null;
    children?: React.ReactNode;
    onChange: (val: boolean) => void;
    required?: boolean,
    errorMessage?: string,
    validationHandler?(): void,
    setErrorMessageHandler?(val: string): void,
    activeTrue?: boolean,
    messageTag?: string
}

/**
 * Represents a user-selectable True/False Radio-Button.
 * 
 * @param {children} children - The children to render when the truthy option is selected.
 * @returns - Renderable JSX element.
 */
export default function BoolField({
    question, answer, children, onChange,
    required, errorMessage,
    setErrorMessageHandler,
    activeTrue = true,
    messageTag
}: Props) {
    function onYesClick(e: React.MouseEvent<HTMLDivElement>) {
        clearErrorMessage();
        onChange(true);
    }

    function onNoClick(e: React.MouseEvent<HTMLDivElement>) {
        clearErrorMessage();
        onChange(false);
    }

    function clearErrorMessage() {
        if (setErrorMessageHandler) {
            setErrorMessageHandler('');
        }
    }

    const displayChildren = activeTrue ? (answer === true ? true : false) : (answer === false ? true : false);

    return(
        <ValidationFieldWrapper
        label={question}
        errorMessage={errorMessage}
        required={required}
        messageTag={messageTag}>
            <div className="flex mt-2">
                <h5 className="mr-2 text-md">Yes</h5>
                <div className="w-7 h-7 bg-gray-300 rounded-lg shadow-inner flex" onClick={onYesClick}>
                    <div className={"w-5 h-5 bg-red-500 m-auto rounded-lg transition-transform duration-200 ease-in-out transform-gpu " + (answer === true && answer != null ? "scale-100" : "scale-0")} />
                </div>

                <h5 className="mr-2 ml-4 text-md">No</h5>
                <div className="w-7 h-7 bg-gray-300 rounded-lg shadow-inner flex" onClick={onNoClick}>
                    <div className={"w-5 h-5 bg-red-500 m-auto rounded-lg transition-transform duration-200 ease-in-out transform-gpu " + (answer === false  && answer != null ? "scale-100" : "scale-0")} />
                </div>
            </div>
            {displayChildren ? children : null}
        </ValidationFieldWrapper>
    );

}