export type Props = {
    label: string,
    required?: boolean,
    errorMessage?: string,
    noMargin?: boolean
};

export default function Label({label, required, errorMessage, noMargin = false}: Props) {
    return (
        <label 
        className={`font-bold form-label inline-block text-gray-500 ${noMargin ? '' : 'mb-2'}`}
        >{label}{required ? <span className={errorMessage ? 'text-red-500' : ''}> *</span> : null}</label>
    );
}