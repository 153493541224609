import { AnimatePresence, motion } from "framer-motion";
import ReactDOM from "react-dom";
import SampleMedia from '../../assets/img/sampleMedia.png'

type props = {
    closeCallback: Function
    imageURL: string
}

export const ImageModal = ({closeCallback, imageURL}:props) => {

    return(
        ReactDOM.createPortal(

            // <motion.div key="modal-bg" className="absolute bg-black/40 inset-0 z-50 flex w-screen h-screen" onClick={() => closeCallback()} initial={{opacity: "0%"}} animate={{opacity: "100%"}} exit={{opacity: "0%"}} transition={{duration: 0.2}}>
            //     <motion.div key="modal-content" className="bg-white my-auto md:mx-auto mx-4 p-4 rounded-md shadow-md flex flex-col space-y-4 md:w-96" initial={{scale: "0%"}} animate={{scale: "100%"}} exit={{scale: "0%"}} transition={{type: "keyframes", duration: 0.2}}>
            <div key="modal-bg" className="absolute bg-black/40 inset-0 z-50 flex w-screen h-screen" onClick={() => closeCallback()}>
                <div key="modal-content" className="bg-white my-auto md:mx-auto mx-4 p-4 rounded-md shadow-md flex flex-col space-y-4 md:w-96">
                    <img className="shadow-md" src={imageURL}/>
                    <div className='flex space-x-2'>
                        <button className="mx-auto bg-gradient-to-t from-gray-300 to-gray-200 shadow-md flex-1 text-center rounded-lg"><p className="px-4 py-2 text-gray-500 font-semibold">Close</p></button>
                        <button className="mx-auto bg-gradient-to-t from-red-500 to-red-400 shadow-md flex-1 text-center rounded-lg"><p className="px-4 py-2 text-white font-semibold">Save</p></button>
                    </div>
                </div>
            </div>
            //     </motion.div>
            // </motion.div>
            ,
            document.getElementById("modal-root")!
        )
    )

}