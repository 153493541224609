export type Props = {
    label: string
};

export default function HeadingLabel({label}: Props) {
    return (
        <label 
        className="font-bold 
        form-label 
        text-lg
        inline-block mb-1
        text-gray-700"
        >{label}</label>
    );
}