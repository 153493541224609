export type Props = {
    progress: number;
};

export default function ProgressBar({progress}: Props) {
    const progressPercentage = `${Math.trunc(progress)}%`;
    return(
    <div className='w-full h-6 bg-gray-200 rounded-xl overflow-hidden'>
        <div style={{width: progressPercentage}} className="h-full bg-red-400 rounded-l-xl flex justify-end items-center">
            <div style={{height: 'min-content'}} className="text-white font-bold text-sm px-2">{progressPercentage}</div>
        </div>
    </div>);
}