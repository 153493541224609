import React, { useEffect, useState } from "react";
import { useCallback, useMemo } from "react";
import Label from './Label';
import ValidationFieldWrapper from "./ValidatedFieldWrapper";

export type Props = {
    label: string,
    value: string,
    onChange(val: string): void,
    options: Array<string>,
    required?: boolean,
    errorMessage?: string,
    setErrorMessageHandler?(val: string): void
};

export default function SelectField({label, value, onChange, options, required, errorMessage, setErrorMessageHandler}: Props) {
    const optionElements = useMemo(() => {
      var res = options.map(o => <option key={o}>{o}</option>);
      res.push(<option key={''} value={''} disabled hidden>Please select</option>)
      return res;
    }
    , [options]);

    function onChangeImpl(e: React.ChangeEvent<HTMLSelectElement>) {
      onChange(e.target.value);
    }

    useEffect(() => {
      if (setErrorMessageHandler) {
          setErrorMessageHandler('');
      }
    }, [value]);

    return(
      <ValidationFieldWrapper
        label={label}
        required={required}
        errorMessage={errorMessage}
        >
          <div className="relative">
              <select value={value} onChange={onChangeImpl} className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                {optionElements}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
        </ValidationFieldWrapper>
        // <div className="w-full">
        //     <Label label={label} />
        //     <div className="relative">
        //       <select value={value} onChange={onChangeImpl} className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
        //         {optionElements}
        //       </select>
        //       <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        //         <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        //       </div>
        //     </div>
        //   </div>
    );
}