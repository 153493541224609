/**
 * Uploads an image to Azure Blob Storage.
 * 
 * @param image - The image to be uploaded to the blob storage.
 * @param plate - The plate number used to name the container.
 * @returns - The URL to the image once successfully uploaded.
 */

import { apiConfig } from "../apiConfig";
import { DisplayMessageError, MessageType } from "../components/Messages/Message";
import { UnauthorizedError } from "../exceptions/UnauthorizedError";

type uploadImageResponse = {
    imageUrl: string,
    success: boolean
}

export const uploadImage = async (image: File, plate: string, token: string): Promise<uploadImageResponse>  => {

    //Calculate current date
    const date: Date = new Date();
    const currentDate: string = date.getDate().toString() + date.getMonth().toString() + date.getFullYear().toString();

    //Construct form data
    const formData: FormData = new FormData();

    formData.append("File", image);
    formData.append("Plate", plate.toLowerCase());
    formData.append("Date", currentDate);

    //Post the data
    const res = await fetch(

        //API endpoint
        apiConfig.fiveStarBaseUrl + "image/upload",

        {
            mode: "cors",       //Enable CORS
            method: "POST",     //Use POST method
            headers: {
                'Authorization': `Bearer ${token}`,
                'X-Consumer-Id': 'Vending'
            },
            body: formData      //Image, plate and date data
        }
    );

    //Error checking
    if (res.status === 401) {
        throw new UnauthorizedError();
    }
    else if(!res.ok) {
      console.log("ERROR " + res.status + " WHEN POSTING IMAGE")
      throw new DisplayMessageError("An error occured while attempting to post image.", MessageType.ERROR);
    }

    //Get response and pass to string
    const data = await res.json();
    return data;

}