import { useEffect, useState, useCallback } from "react";
import {useRecoilState, useSetRecoilState } from 'recoil';
import FieldGrouping from './FieldGrouping';
import { defaultRequiredStringInputValidator, emailInputValidator, isValid, nullEmailInputValidator, nullPhoneInputValidator, phoneInputValidator } from "./Validation";
import {
    validationFnState,
    mainContactFirstNameState,
    mainContactLastNameState,
    mainContactEmailState,
    mainContactPhoneState,
    ccepContactFirstNameState,
    ccepContactLastNameState,
    ccepContactEmailState,
    ccepContactPhoneState,
    alternateContactFirstNameState,
    alternateContactLastNameState,
    alternateContactEmailState,
    alternateContactPhoneState,
} from './SurveyAtoms'
import InputField from "./InputField";

export default function ContactSurvey() {
    const setValidationFn = useSetRecoilState(validationFnState);

    // Main Contact
    const [mainContactFirstName, setMainContactFirstName] = useRecoilState(mainContactFirstNameState);
    const [mainContactLastName, setMainContactLastName] = useRecoilState(mainContactLastNameState);
    const [mainContactEmail, setMainContactEmail] = useRecoilState(mainContactEmailState);
    const [mainContactPhone, setMainContactPhone] = useRecoilState(mainContactPhoneState);

    const [mainContactFirstNameError, setMainContactFirstNameError] = useState('');
    const [mainContactLastNameError, setMainContactLastNameError] = useState('');
    const [mainContactEmailError, setMainContactEmailError] = useState('');
    const [mainContactPhoneError, setMainContactPhoneError] = useState('');

    const mainContactFirstNameValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        mainContactFirstName,
        'First name is required',
        setMainContactFirstNameError), [mainContactFirstName]);

    const mainContactLastNameValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        mainContactLastName,
        'Last name is required',
        setMainContactLastNameError), [mainContactLastName]);

    const mainContactEmailValidationHandler = useCallback(() => emailInputValidator(
        mainContactEmail,
        'Valid email required',
        setMainContactEmailError), [mainContactEmail]);

    const mainContactPhoneValidationHandler = useCallback(() => phoneInputValidator(
        mainContactPhone,
        'Valid phone required',
        setMainContactPhoneError), [mainContactPhone]);

    // CCEP Contact
    const [ccepContactFirstName, setCcepContactFirstName] = useRecoilState(ccepContactFirstNameState);
    const [ccepContactLastName, setCcepContactLastName] = useRecoilState(ccepContactLastNameState);
    const [ccepContactEmail, setCcepContactEmail] = useRecoilState(ccepContactEmailState);
    const [ccepContactPhone, setCcepContactPhone] = useRecoilState(ccepContactPhoneState);

    const [ccepContactFirstNameError, setCcepContactFirstNameError] = useState('');
    const [ccepContactLastNameError, setCcepContactLastNameError] = useState('');
    const [ccepContactEmailError, setCcepContactEmailError] = useState('');
    const [ccepContactPhoneError, setCcepContactPhoneError] = useState('');

    const ccepContactFirstNameValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        ccepContactFirstName,
        'First name is required',
        setCcepContactFirstNameError), [ccepContactFirstName]);

    const ccepContactLastNameValidationHandler = useCallback(() => defaultRequiredStringInputValidator(
        ccepContactLastName,
        'Last name is required',
        setCcepContactLastNameError), [ccepContactLastName]);

    const ccepContactEmailValidationHandler = useCallback(() => emailInputValidator(
        ccepContactEmail,
        'Valid email required',
        setCcepContactEmailError), [ccepContactEmail]);

    const ccepContactPhoneValidationHandler = useCallback(() => phoneInputValidator(
        ccepContactPhone,
        'Valid phone required',
        setCcepContactPhoneError), [ccepContactPhone]);

    // Alternate Contact
    const [alternateContactFirstName, setAlternateContactFirstName] = useRecoilState(alternateContactFirstNameState);
    const [alternateContactLastName, setAlternateContactLastName] = useRecoilState(alternateContactLastNameState);
    const [alternateContactEmail, setAlternateContactEmail] = useRecoilState(alternateContactEmailState);
    const [alternateContactPhone, setAlternateContactPhone] = useRecoilState(alternateContactPhoneState);

    const [alternateContactEmailError, setAlternateContactEmailError] = useState('');
    const [alternateContactPhoneError, setAlternateContactPhoneError] = useState('');

    const alternateContactEmailValidationHandler = useCallback(() => nullEmailInputValidator(
        alternateContactEmail,
        'Valid email required',
        setAlternateContactEmailError) , [alternateContactEmail]);

    const alternateContactPhoneValidationHandler = useCallback(() => nullPhoneInputValidator(
        alternateContactPhone,
        'Valid phone required',
        setAlternateContactPhoneError), [alternateContactPhone]);

    const validatorFuncs = [
        mainContactFirstNameValidationHandler,
        mainContactLastNameValidationHandler,
        mainContactEmailValidationHandler,
        mainContactPhoneValidationHandler,
        ccepContactFirstNameValidationHandler,
        ccepContactLastNameValidationHandler,
        ccepContactEmailValidationHandler,
        ccepContactPhoneValidationHandler,
        alternateContactEmailValidationHandler,
        alternateContactPhoneValidationHandler
    ];

    const formValidator = useCallback((): boolean => {
            console.log('Outlet form validator run.');
            return isValid(validatorFuncs)
        },
        validatorFuncs);

    useEffect(() => {
        setValidationFn(() => {
            return formValidator
        });
    }, [formValidator]);

    return (        
        <div className="w-full max-w-lg px-4 pt-4 flex flex-col gap-4 mb-6">
            <FieldGrouping label='Main Contact' >
                <div className='grid grid-cols-2 gap-4'>
                    <InputField label="First Name"
                    value={mainContactFirstName}
                    onChange={setMainContactFirstName}
                    required
                    errorMessage={mainContactFirstNameError}
                    validationHandler={mainContactFirstNameValidationHandler}
                    setErrorMessageHandler={setMainContactFirstNameError}
                    />
                    <InputField label="Last Name"
                    value={mainContactLastName}
                    onChange={setMainContactLastName}
                    required
                    errorMessage={mainContactLastNameError}
                    validationHandler={mainContactLastNameValidationHandler}
                    setErrorMessageHandler={setMainContactLastNameError}
                    />
                </div>
                <InputField label="Email"
                    value={mainContactEmail}
                    onChange={setMainContactEmail}
                    required
                    errorMessage={mainContactEmailError}
                    validationHandler={mainContactEmailValidationHandler}
                    setErrorMessageHandler={setMainContactEmailError}
                    />
                <InputField type='tel' label="Phone"
                    value={mainContactPhone}
                    onChange={setMainContactPhone}
                    required
                    errorMessage={mainContactPhoneError}
                    validationHandler={mainContactPhoneValidationHandler}
                    setErrorMessageHandler={setMainContactPhoneError}
                    />
            </FieldGrouping>
            <FieldGrouping label='CCEP Contact' >
                <div className='grid grid-cols-2 gap-4'>
                    <InputField label="First Name"
                    value={ccepContactFirstName}
                    onChange={setCcepContactFirstName}
                    required
                    errorMessage={ccepContactFirstNameError}
                    validationHandler={ccepContactFirstNameValidationHandler}
                    setErrorMessageHandler={setCcepContactFirstNameError}
                    />
                    <InputField label="Last Name"
                    value={ccepContactLastName}
                    onChange={setCcepContactLastName}
                    required
                    errorMessage={ccepContactLastNameError}
                    validationHandler={ccepContactLastNameValidationHandler}
                    setErrorMessageHandler={setCcepContactLastNameError}
                    />
                </div>
                <InputField label="Email"
                    value={ccepContactEmail}
                    onChange={setCcepContactEmail}
                    required
                    errorMessage={ccepContactEmailError}
                    validationHandler={ccepContactEmailValidationHandler}
                    setErrorMessageHandler={setCcepContactEmailError}
                    />
                <InputField 
                    type="tel"
                    label="Phone"
                    value={ccepContactPhone}
                    onChange={setCcepContactPhone}
                    required
                    errorMessage={ccepContactPhoneError}
                    validationHandler={ccepContactPhoneValidationHandler}
                    setErrorMessageHandler={setCcepContactPhoneError}
                    />
            </FieldGrouping>
            <FieldGrouping label='Alternate Contact' >
                <div className='grid grid-cols-2 gap-4'>
                    <InputField label="First Name"
                    value={alternateContactFirstName}
                    onChange={setAlternateContactFirstName}
                    />
                    <InputField label="Last Name"
                    value={alternateContactLastName}
                    onChange={setAlternateContactLastName}
                    />
                </div>
                <InputField label="Email"
                    value={alternateContactEmail}
                    onChange={setAlternateContactEmail}
                    required
                    errorMessage={alternateContactEmailError}
                    validationHandler={alternateContactEmailValidationHandler}
                    setErrorMessageHandler={setAlternateContactEmailError}
                    />
                <InputField label="Phone"
                    type="tel"
                    value={alternateContactPhone}
                    onChange={setAlternateContactPhone}
                    required
                    errorMessage={alternateContactPhoneError}
                    validationHandler={alternateContactPhoneValidationHandler}
                    setErrorMessageHandler={setAlternateContactPhoneError}
                    />
            </FieldGrouping>
    </div>);
}