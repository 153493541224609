import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import SampleMedia from '../../assets/img/sampleMedia.png'
import LoadingSpinner from '../Spinners/LoadingSpinner';
import { ImageModal } from './ImageModal';

type props = {
    imgUrl: string
    deleteCallback: Function
}

export const ImagePreviewContainer = ({imgUrl, deleteCallback} : props) => {

    const [loaded, setLoaded] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const originalUrl = imgUrl.endsWith('*') ? imgUrl.slice(0, -1) : imgUrl

    return(
        <div>
            {/* <AnimatePresence> */}
            {modalOpen && <ImageModal key="modal" imageURL={originalUrl} closeCallback={() => setModalOpen(false)} />}
            {/* </AnimatePresence> */}
            {/* <AnimatePresence> */}
                {/* <motion.div key={imgUrl} className='relative flex' initial={{scale: "0%"}} animate={{scale: "100%"}} exit={{scale: "0%"}}> */}
                <div key={imgUrl} className='relative flex'>
                    {(!imgUrl.endsWith('*')) ?
                    <button onClick={() => deleteCallback(imgUrl)} className="w-7 h-7 bg-red-500 rounded-full absolute top-[-10px] left-[-10px] flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x text-white my-auto mx-auto w-7 h-7" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </button> : null}
                    {(loaded) ? <img src={originalUrl} className="w-[4.5rem] h-[4.5rem] rounded-md object-cover" onClick={() => setModalOpen(true)}/> : <div className="w-[4.5rem] h-[4.5rem]"><LoadingSpinner/></div>}
                </div>
                {/* </motion.div> */}
            {/* </AnimatePresence> */}
        </div>
        
    )

}