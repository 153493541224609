import { RatingType } from "../context/equipment/EquipmentReducer";
import { EquipmentStoreData } from "../context/equipment/EquipmentState";

const allMissingRatings: string[] = [
    RatingType.payment.toString(),
    RatingType.position.toString(),
    RatingType.price.toString(),
    RatingType.range.toString(),
    RatingType.visibility.toString()
]

// crude client side validation
export const validateEquipmentData = (data: EquipmentStoreData) => {
    // user input is only possible for categories so we only need to validate that
    // the rest is automatically generated by the app and should not have an error.
    
    // if (data.outlet === null || data.outlet === undefined) {
    //     return {valid: false, message: 'Survey could not be submitted outlet is missing.'}
    // }

    // if (data.date === null || data.date === undefined) {
    //     return {valid: false, message: 'Survey could not be submitted date is missing.'}
    // }

    if (data.equipment === null || data.equipment === undefined) {
        return {valid: false, message: 'Survey could not be submitted equipment identifier is missing.'}
    }

    var missingRatings: string[] = []

    //If no report exists, generate new empty report data
    if (data.ratings === undefined || data.ratings === null) {
        missingRatings = allMissingRatings
    } else {
        const ratingNames = data.ratings.map(rating => rating.name)

        for (let rating in RatingType) {
            if (!ratingNames.includes(rating)) {
                missingRatings.push(rating);
                continue;
            }
    
            const ratingVal = data.ratings.find(r => r.name === rating)
    
            const flag = ratingVal?.flag;

            if (flag === null || flag === undefined) {
                missingRatings.push(rating);
                continue;
            }

            const reasonCode = ratingVal?.reasonCode;

            if ((reasonCode === null || reasonCode === undefined) 
                && !flag) {
                missingRatings.push(rating);
                continue;
            }
        }
    }

    if (missingRatings.length > 0) {
        return {valid: false, message: `Survey is missing ratings for ${missingRatings.join(', ')}`}
    }

    return {valid: true, message: ''}
}