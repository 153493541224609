import {useState, useEffect } from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import NavigationButton from "../components/Buttons/NavigationButton";
import OutletSurvey from "../components/Survey/OutletSurvey";
import EntryRequirements from "../components/Survey/EntryRequirements";
import AccessibilitySurvey from "../components/Survey/AccessibilitySurvey";
import ProgressBar from "../components/Survey/ProgressBar";
import { Driver, driverState, Outlet, useResetForm, validationFnState } from '../components/Survey/SurveyAtoms';
import Modal from '../components/Survey/Modal';
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { getIdToken, loginRedirect } from '../helpers/ApiUtils';
import { apiConfig } from '../apiConfig';
import { 
    outletState
} from '../components/Survey/SurveyAtoms';
import LoadingSpinner from '../components/Spinners/LoadingSpinner';
import { DisplayMessageError, Message, MessageType } from '../components/Messages/Message';
import { UnauthorizedError } from '../exceptions/UnauthorizedError';
import { headingState } from './Landing';
import { isSamV } from '../helpers/UserAgentUtils';
import ContactSurvey from '../components/Survey/ContactSurvey';

export type SurveyRefType = {
    isValid: () => boolean;
};

export type SurveyFnType = () => boolean;

export type DispatchSurveyFnType = React.Dispatch<React.SetStateAction<SurveyFnType | undefined>>;

type SurveyStep = {
    name: string,
    component: JSX.Element
}

const steps: SurveyStep[] = [
    {
        name: 'Confirm Outlet Details',
        component: <OutletSurvey />
    }, 
    {
        name: 'Confirm Contact Details',
        component: <ContactSurvey />
    }, 
    {
        name: 'Confirm Prerequisites',
        component: <EntryRequirements />
    },
    {
        name: 'Confirm Prerequisites',
        component: <AccessibilitySurvey />
    }
];

const Survey = () => {
    const setHeading = useSetRecoilState(headingState);
    const navigate = useNavigate();
    const validationFn = useRecoilValue(validationFnState);
    const resetForm = useResetForm();
    const [step, setStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({
        message: '',
        type: MessageType.ERROR
    });
    const [hasError, setHasError] = useState(false);

    const setOutletInfo = useSetRecoilState<Outlet | null>(outletState);
    const setDriver = useSetRecoilState<Driver | null>(driverState);

    const numSteps = steps.length;
    // const disableBack = step <= 0;
    // const disableNext = step >= numSteps - 1;
    const displayStep = step + 1;
    const progress = (displayStep * 100) / numSteps;
    const stepDetails = `Step ${displayStep} of ${numSteps} - ${steps[step].name}`;
    const isFirstStep = displayStep == 1;
    const isLastStep = displayStep == numSteps;
    const isSamVFlag = isSamV();

    // Msal hook
    const {instance, accounts} = useMsal();

    useEffect(() => {
        const fetchOutlet = async () =>  {
            try {
                resetForm();
                setHasError(false);

                //Extract plate number from URL
                const plateInput = new URLSearchParams(window.location.search).get("plate");

                //Guard ensuring plate number was provided
                if(!plateInput){
                    throw new DisplayMessageError("This machine does not exist. Please check configuration in SAP.", MessageType.ERROR);
                }

                const token = await getIdToken(instance, accounts[0]);

                const res = await fetch(//Fetch equipment data.
                    apiConfig.closeSiteSurveyBaseUrl + "equipments/" + plateInput,
                    {
                        mode: "cors",       //Enable CORS.
                        method: "GET",     //Use GET method.
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'X-Consumer-Id': 'Vending'
                        }
                    });

                if (res.ok) {
                    var json = await res.json();
                    
                    if (!json) {
                        throw new DisplayMessageError("An error occured attempting to load equipment data for Plate number: " + plateInput, MessageType.ERROR);
                    }

                    if (!json.outlet) {
                        throw new DisplayMessageError("Outlet info is missing: " + plateInput, MessageType.ERROR);
                    } else {
                        if (!(json.outlet?.address &&
                            json.outlet?.equipment &&
                            json.outlet?.outletName &&
                            json.outlet?.outletNumber &&
                            json.outlet?.plateNumber &&
                            json.driver?.name &&
                            json.driver?.phone)) {
                            throw new DisplayMessageError("Outlet info is missing: " + plateInput, MessageType.ERROR);
                        }

                        setHeading({
                            title: json.outlet.plateNumber,
                            subTitle: json.outlet.outletName
                        });

                        setDriver(json.driver);
                        setOutletInfo(json.outlet);
                    }
                } else if (res.status === 401) {
                    throw new UnauthorizedError();
                } else if (res.status === 403) {
                    throw new DisplayMessageError("You do not have authorization to access this resource.", MessageType.ERROR);
                } else {
                    throw new DisplayMessageError("An error occured attempting to load equipment data for Plate number: " + plateInput, MessageType.ERROR);
                }
            } catch (err) {
                if (err instanceof UnauthorizedError) {
                    await loginRedirect(instance, accounts[0]);
                }
                else if (err instanceof DisplayMessageError) {
                    // display error message modal
                    const displayError = err as DisplayMessageError;

                    setError({
                        message: displayError.message,
                        type: displayError.type
                    });
                } else {
                    console.log("Unhandled exception: " + err)
                    // display unhandled error message modal
                    setError({
                        message: 'An unexpected error occured.',
                        type: MessageType.ERROR
                    });
                }

                setHasError(true);
            } finally {
                //Disable loading state, once all data has been loaded.
                setLoading(false);
            }
        };                                                                                                                                             

        fetchOutlet();
    }, []);

    function renderStep() {
        return steps[step].component;

        // return createElement(ComponentName);
    }

    // useEffect(() => { console.log(ref); }, []);

    function nextStep() {
        console.log('next step called');

        if (step >= numSteps - 1) {
            return;
        }

        if (!validationFn()) {
            console.log('Form is invalid');
            // alert('Please resolve the above input errors to continue');
            openModal();
            return;
        }
        
        setStep(step + 1);
    }

    function goBack() {
        if (step <= 0) {
            return;
        }

        setStep(step - 1);
    }

    const openModal = () => {
        setShowModal(true);
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const submitForm = () => {
        if (!validationFn()) {
            console.log('Form is invalid');
            // alert('Please resolve the above input errors to continue');
            openModal();
            return;
        }

        navigate("/close-site/submit");
    }

    const goHome = () => {
        navigate("/close-site");
        }

    return (
        <>
            {
                loading ? //Display loading spinner while the data is loading.
                <div className="flex flex-col justify-center text-center my-auto">
                        <div className='mx-auto'><LoadingSpinner/></div>
                        <h1 className="text-xl font-semibold text-gray-600">Loading</h1>
                </div> 
                : 
                hasError ?
                <div>
                    <Message type={error.type} message={error.message} />
                    {/* Bottom Nav Buttons */}
                        <div className="p-4 space-x-5 mb-0 flex z-10">
                            {/* Exit Button */}
                            <NavigationButton text="Exit" dominant={false} disabled={loading && (!hasError)}
                                action={() => {

                                    goHome();
                                    
                                }}
                            />
                        </div>
                </div>
                :
                <div className="survey__container max-w-lg w-full">
                    <div className="survey__body">
                        <div className='px-4 mb-4'>
                            <label className="font-bold 
                    form-label 
                    inline-block mb-2 
                    text-gray-400">
                            {stepDetails}
                            </label>
                            <ProgressBar progress={progress}  />
                        </div>
                        {renderStep()}
                    </div>
                    <div className="p-4 space-x-5 mb-0 flex">
                        {isSamVFlag && isFirstStep ? null : <NavigationButton text={isFirstStep ? 'Exit' : 'Back'} disabled={false} dominant={false} action={isFirstStep ? goHome : goBack}/>}
                        <NavigationButton text={isLastStep ? 'Submit' : 'Next'} disabled={false} dominant={true} action={isLastStep ? submitForm : nextStep} />
                    </div>
                    <Modal showModal={showModal} closeModal={closeModal} title={'Validation error'} saveText={'Back to form'} noHeader={true} onSave={closeModal} singleButton >
                        <div className='p-6 flex gap-6 flex-col'>
                            <div className='font-bold 
                            form-label 
                            inline-block
                            text-gray-500'>Oops you've made an <span className='text-red-400 italic'>error(s)</span> completing this form.</div>
                            <div className='font-bold 
                            form-label 
                            inline-block
                            text-gray-500'>Please resolve it to continue.</div>
                        </div>
                    </Modal>
                </div>
            }
    </>);
}

export default Survey;