import {msalConfig} from '../authConfig'

export const getIdToken = async (instance: any, account: any) => {
    const idTokenClaims = account.idTokenClaims;
    const dateTime = new Date().getTime() / 1000;
    console.log(`expiry: ${idTokenClaims.exp}`)
    console.log(`dateTime: ${dateTime}`)
    const forceRefresh = (new Date(idTokenClaims.exp + msalConfig.auth.refreshWindow).getTime() < dateTime);

    const tokenRequest = {
      account,
      forceRefresh: forceRefresh
    }

    try {
  
      const authResponse = await instance
          .acquireTokenSilent(tokenRequest);
  
      return authResponse.idToken;
    } catch (err: any) {
      if (err.name === "InteractionRequiredAuthError") {
          // throw new DisplayMessageError("Session expired please login.", MessageType.WARNING)
          // redirects user to log back into the app
          await instance.acquireTokenRedirect(tokenRequest);
      }
    }
}

export const loginRedirect = async (instance: any, account: any) => {
    const tokenRequest = {
      account,
      forceRefresh: true,
      scopes: []
    };
    
    await instance.acquireTokenRedirect(tokenRequest);
}