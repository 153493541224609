import React, { useState } from "react";

/**
 * Props for NavigationButton component
 */
type Props = {
    text: string,
    dominant: boolean,
    disabled: boolean,
    action: Function,
    id?: string
}

/**
 * Button used for navigation at the bottom of the screen.
 * 
 * @param {string} text - Text to be displayed on the navigation button.
 * @param {boolean} dominant - Is the button the dominant button on the page (is highlighted in red).
 * @param {boolean} disabled - Is the button disabled.
 * @param {Function} action - The action to be performed on click.
 * @param {string} id - Id for the element
 * @returns - A JSX element containing the navigation button.
 */
const NavigationButton = ({text, dominant, disabled, action, id} : Props) =>{

    //Use effect state for button press animation
    const [useEffect, setEffect] = useState(false);

    //Calculate button style
    var bgColor = (disabled === true) ? "bg-gray-300 shadow-inner" : 
        (dominant === true) ? "bg-gradient-to-t from-red-500 to-red-400 shadow-md" : "bg-gradient-to-t from-gray-500 to-gray-400 shadow-md";

    var style = `${useEffect && "animate-buttonDown"} flex-1 text-center rounded-lg ` + bgColor;

    return(
        <button id={id} className={style} disabled={disabled} onClick={() => {setEffect(true);}} onAnimationEnd={() => {setEffect(false); action()}}>
                <p className="p-4 text-white font-semibold">{text}</p>
        </button>
    );    
    
}

export default NavigationButton;