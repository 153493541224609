import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import { EquipmentStateProvider } from './context/equipment/EquipmentState';
import { CategoryStateProvider } from './context/category/CategoryState';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { PageLayout } from './components/Containers/Navigation';
import { RecoilRoot } from 'recoil';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders. 
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
console.log(msalConfig)
 export const msalInstance = new PublicClientApplication(msalConfig);

console.log(msalInstance.getLogger())

console.log(msalInstance)

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <EquipmentStateProvider>
          <CategoryStateProvider>
            <MsalProvider instance={msalInstance}>
              <PageLayout>
                <App />
              </PageLayout>
            </MsalProvider>
          </CategoryStateProvider>
        </EquipmentStateProvider>
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);
