import { useEffect, useState } from "react";
import NavigationButton from "../components/Buttons/NavigationButton";
import StarContainer from "../components/Containers/ReportContainer"
import { useNavigate } from "react-router-dom";
import { useEquipmentStateContext } from "../context/equipment/EquipmentState";
import { useCategoryStateContext } from "../context/category/CategoryState";
import { EquipmentActionType } from "../context/equipment/EquipmentReducer";
import LoadingSpinner from "../components/Spinners/LoadingSpinner";
import { ErrorSVG } from "../assets/svg/error";
import { CategoryActionType } from "../context/category/CategoryReducer";
import { Message, MessageType } from "../components/Messages/Message";
import { apiConfig } from "../apiConfig";
import { getIdToken, loginRedirect } from "../helpers/ApiUtils";
import { useMsal } from "@azure/msal-react";
import { DisplayMessageError } from "../components/Messages/Message";
import { validateEquipmentData } from "../helpers/ValidationUtils";
import { DisplayErrorModal } from "../components/Containers/DisplayErrorModal";
import { UnauthorizedError } from "../exceptions/UnauthorizedError";
import { useSetRecoilState } from "recoil";
import { headingState } from "./Landing";
import { isSamV } from "../helpers/UserAgentUtils";

/**
 * Page containing all report content
 * 
 * @returns - A JSX element containing the report page contents.
 */
const Report: React.FunctionComponent = () => {

    const setHeading = useSetRecoilState(headingState);

    //Naviagtion hook
    const navigate = useNavigate();

    const isSamVFlag = isSamV();

    // Msal hook
    const {instance, accounts} = useMsal();

    //State to determine if the data is still being loaded
    const [loading, setLoading] = useState(true);
    const [loadingImage, setLoadingImage] = useState(false);

    const [hasError, setHasError] = useState(false);

    const [errorModalOpen, setErrorModalOpen] = useState(false);

    const [errorModalMessage, setErrorModalMessage] = useState('');

    const [error, setError] = useState({
        message: '',
        type: MessageType.ERROR
    });

    //Give access to equipment and category dispatch methods
    const {state: equipmentState, dispatch: equipmentDispatch} = useEquipmentStateContext();
    const { dispatch: categoryDispatch } = useCategoryStateContext();

    if(!equipmentDispatch || !categoryDispatch) throw new Error("Dispatch is null/undefined");

    //Extract plate number from URL
    const plateInput = new URLSearchParams(window.location.search).get("plate");

    const goHome = () => {
        navigate("/");
    }

    useEffect(

        //Load data from the database, passing in the plate number.
        () => {
            async function fetchEquipment() {
                try {
                    setHasError(false)

                    //Guard ensuring plate number was provided
                    if(!plateInput){
                        throw new DisplayMessageError("This machine does not exist. Please check configuration in SAP.", MessageType.ERROR);
                    }

                    const token = await getIdToken(instance, accounts[0]);

                    const res = await fetch(//Fetch equipment data.
                        apiConfig.fiveStarBaseUrl + "equipment/" + plateInput,
                        {
                            mode: "cors",       //Enable CORS.
                            method: "GET",     //Use GET method.
                            headers: {
                                'Authorization': `Bearer ${token}`,
                                'X-Consumer-Id': 'Vending'
                            }
                        });

                    //Check response.
                    if (res.ok) {
                        const data = await res.json();

                        if (data === null) {
                            throw new DisplayMessageError("Plate number: " + plateInput + " could not be found!", MessageType.WARNING);
                        }

                        if (data.division === null || data.division === undefined) {
                            data.division = 'AUS'; // if division is not defined default to AUS
                        }


                        setHeading({
                            title: (data.equipment?.plate) ? data.equipment?.plate : 'Unknown',
                            subTitle: (data.outlet?.number && data.outlet?.name) ? data.outlet.number + " : " + data.outlet.name : ""
                        });

                        //Dispatch data to global state
                        equipmentDispatch({ type: EquipmentActionType.LOAD_DATA, payload: {data: data} })

                        const division = data.division;

                        console.log(division)

                        const categoryRes = await fetch(//Fetch equipment data.
                            apiConfig.fiveStarBaseUrl + "category/" + data.division,
                            {
                                mode: "cors",       //Enable CORS.
                                method: "GET",     //Use GET method.
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'X-Consumer-Id': 'Vending'
                                }
                            });

                        if (categoryRes.ok) {
                            const categoryData = await categoryRes.json()

                            console.log(categoryData)

                            if (categoryData === null) {
                                throw new DisplayMessageError("Could not find category data for division: " + division, MessageType.WARNING);
                            }

                            categoryDispatch({ type: CategoryActionType.LOAD_DATA, payload: {data: categoryData} });
                        } else if (categoryRes.status === 401) {
                            throw new UnauthorizedError();
                        } else {
                            console.log(`Get category err status code: ${categoryRes.status}`)
                            throw new DisplayMessageError("An error occured attempting to load category data for division: " + division, MessageType.ERROR);
                        }
                    } else if (res.status === 401) {
                        throw new UnauthorizedError();
                    }
                    else {
                        console.log(`Get equipment err status code: ${res.status}`)
                        throw new DisplayMessageError("An error occured attempting to load equipment data for Plate number: " + plateInput, MessageType.ERROR);
                    }
                } catch (err) {
                    if (err instanceof UnauthorizedError) {
                        await loginRedirect(instance, accounts[0]);
                    }
                    else if (err instanceof DisplayMessageError) {
                        // display error message modal
                        const displayError = err as DisplayMessageError;

                        setError({
                            message: displayError.message,
                            type: displayError.type
                        });
                    } else {
                        console.log("Unhandled exception: " + err)
                        // display unhandled error message modal
                        setError({
                            message: 'An unexpected error occured.',
                            type: MessageType.ERROR
                        });
                    }

                    setHasError(true);
                } finally {
                    //Disable loading state, once all data has been loaded.
                    setLoading(false);
                }
            }

            fetchEquipment()

        }, [equipmentDispatch, categoryDispatch, plateInput]);
    
    return(
        <div style={{maxWidth: '30rem'}} className="flex flex-col flex-1 mb-0 items-center" id="modal-root">

            {(loading) ? 

                //Display loading spinner while the data is loading.
                <div className="flex flex-col justify-center text-center my-auto">
                        <div className='mx-auto'><LoadingSpinner/></div>
                        <h1 className="text-xl font-semibold text-gray-600">Loading</h1>
                </div>

            :
                
                !hasError ? //!doesNotExist && !noOutlet) ?

                    //If the data was loaded, display the report.
                    <>
                        <StarContainer setReportLoading={setLoadingImage}/>
                        {/* Bottom Nav Buttons */}
                        <div className="p-4 space-x-5 mb-0 flex z-10 self-stretch">
                            
                            {isSamVFlag ? null : 
                            <NavigationButton text="Exit" dominant={false} disabled={loading && (!hasError)}
                                action={() => {

                                    equipmentDispatch({type: EquipmentActionType.CLEAR_DATA});
                                    categoryDispatch({type: CategoryActionType.CLEAR_DATA});
                                    navigate("/");
                                    
                                }}
                            />
                            }
                            {/* Exit Button */}

                            {

                                //If the data was loaded, display the submit button
                                (!hasError) ?
                                    <NavigationButton text="Submit" dominant={true} disabled={loadingImage || (loading && (!hasError))} 
                                        action={() => {
                                            const validationResult = validateEquipmentData(equipmentState)

                                            if (!validationResult.valid) {
                                                // open modal
                                                setErrorModalMessage(validationResult.message)

                                                setErrorModalOpen(true)

                                                return
                                            }

                                            equipmentDispatch({ type: EquipmentActionType.UPDATE_LAST_SURVEY, payload: {date: new Date().toJSON()} })

                                            navigate("/submit");                            
                                        }}
                                    />
                                :
                                <></>

                            }
                        </div>
                    </>

                :
                <div>
                    <Message type={error.type} message={error.message} />
                        {/* Bottom Nav Buttons */}
                            <div className="p-4 space-x-5 mb-0 flex z-10">
                                {/* Exit Button */}
                                <NavigationButton text="Exit" dominant={false} disabled={loading && (!hasError)}
                                    action={() => {

                                        goHome();
                                        
                                    }}
                                />
                            </div>
                    </div>
                    //Otherwise, display the appropriate error message, depending on what went wrong.
                    // doesNotExist && <Message type={MessageType.ERROR} message="This machine does not exist. Please check configuration in SAP."/>
                    // ||
                    // noOutlet && <Message type={MessageType.WARNING} message="This machine is not linked to an outlet. Please check configuration in SAP."/>
                     
            }
            {errorModalOpen && <DisplayErrorModal key="validateErrorModal" message={errorModalMessage} closeCallback={() => setErrorModalOpen(false)} />}
        </div>
        
    );

}

export default Report;