import Label from './Label';

export type Props = {
    name: string,
    val: string
};

export default function DisplayField(props: Props) {
    const {name, val} = props;

    return (
    <div className="w-full">
        <Label label={name} />
        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" value={val} disabled={true} 
        style={{WebkitTextFillColor: '#374151', opacity: 1}}/>
    </div>
    );
}